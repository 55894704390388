import { DialogTrigger } from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { SquarePen } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Dialog } from '@/components/ui/dialog';
import { DataCell } from '@/components/ui/record-cell';
import { Separator } from '@/components/ui/separator';
import { Scolarity, Student } from '@/types/types';

import { PastScolarityEdit } from './PastScolarityEdit';

export const PastScolarityBlock = ({
  infos,
  studentInfos,
  triggerEdit,
}: {
  infos: Scolarity;
  studentInfos: Student;
  triggerEdit: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const getLastPlaceAttended =
    studentInfos?.lastPlaceAttended ??
    `${infos.lastPlaceAttended} - ${infos.lastPlaceAttendedPostalCode} ${infos.lastPlaceAttendedCity}`;

  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex flex-wrap justify-between">
        <h3 className="text-xl font-bold text-previsionblue">Scolarité</h3>
        <Dialog>
          <DialogTrigger asChild>
            <Button
              variant="outline"
              className="w-auto"
              onClick={() => {
                triggerEdit('scolarity');
              }}
            >
              <SquarePen size={24} />
            </Button>
          </DialogTrigger>
          <PastScolarityEdit key={Math.random()} />
        </Dialog>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <DataCell
          title="Dernier étalissement fréquenté"
          value={getLastPlaceAttended ? getLastPlaceAttended : 'Non renseigné'}
          valueClassName="mx-2 my-auto"
        />
        <DataCell
          title="Classe actuelle"
          value={
            infos.initialGradeLevel
              ? infos.initialGradeLevel === 'eleventhGrade'
                ? 'Première'
                : 'Terminale'
              : 'Non renseignée'
          }
        />
      </div>

      <Accordion
        type="single"
        collapsible
        className="mx-2"
        defaultValue={
          infos.twelveGradesSpecialities
            ? 'twelveGradesSpecialities'
            : 'elevenGradesSpecialities'
        }
      >
        <AccordionItem value="twelveGradesSpecialities" className="border-b-0">
          <AccordionTrigger className="text-small py-2 font-medium text-gray-500 hover:no-underline">
            Spécialités - Terminale
          </AccordionTrigger>
          <AccordionContent className="w-full space-y-2 pb-0">
            <div
              className={clsx(
                'grid-cols-3',
                infos.initialGradeLevel === 'twelfthGrade' ? 'grid' : 'hidden',
              )}
            >
              <DataCell
                title="Langue apprise"
                value={infos.learnedLanguage === 'english' ? '• Anglais' : '• Français'}
              />
              <DataCell
                title={`Moyenne ${infos.learnedLanguage === 'english' ? 'Anglais' : 'Français'}`}
                value={
                  infos.learnedlanguageAverageResults
                    ? `• ${infos.learnedlanguageAverageResults}`
                    : 'Non renseignée'
                }
              />
              {infos.learnedlanguageCommentaries && (
                <DataCell
                  title="Remarques"
                  value={`• ${infos.learnedlanguageCommentaries}`}
                />
              )}
            </div>
            <div className="grid grid-cols-3">
              <DataCell
                subtitle="Spécialité 1"
                value={
                  infos.twelveGradesSpecialities?.one
                    ? `• ${infos.twelveGradesSpecialities.one}`
                    : '• Non renseignée'
                }
              />
              <DataCell
                subtitle="Moyenne"
                value={
                  infos.twelveGradesSpecialities?.averageResults?.one
                    ? `• ${infos.twelveGradesSpecialities.averageResults.one}`
                    : '• Non renseignée'
                }
              />
              {infos.twelveGradesSpecialities?.commentaries?.one && (
                <DataCell
                  subtitle="Remarques"
                  value={`• ${infos.twelveGradesSpecialities?.commentaries?.one}`}
                />
              )}
            </div>
            <div className="grid grid-cols-3">
              <DataCell
                subtitle="Spécialité 2"
                value={
                  infos.twelveGradesSpecialities?.two
                    ? `• ${infos.twelveGradesSpecialities.two}`
                    : '• Non renseignée'
                }
              />
              <DataCell
                subtitle="Moyenne"
                value={
                  infos.twelveGradesSpecialities?.averageResults?.two
                    ? `• ${infos.twelveGradesSpecialities.averageResults.two}`
                    : '• Non renseignée'
                }
              />
              {infos.twelveGradesSpecialities?.commentaries?.two && (
                <DataCell
                  subtitle="Remarques"
                  value={`• ${infos.twelveGradesSpecialities?.commentaries?.two}`}
                />
              )}
            </div>
            <div className="grid grid-cols-3">
              <DataCell
                subtitle="Spécialité abandonnée en Première"
                value={
                  infos.twelveGradesSpecialities?.dropped
                    ? `• ${infos.twelveGradesSpecialities.dropped}`
                    : '• Non renseignée'
                }
              />
              <DataCell
                subtitle="Moyenne"
                value={
                  infos.twelveGradesSpecialities?.averageResults?.dropped
                    ? `• ${infos.twelveGradesSpecialities.averageResults.dropped}`
                    : '• Non renseignée'
                }
              />

              {infos.twelveGradesSpecialities?.commentaries?.dropped && (
                <DataCell
                  subtitle="Remarques"
                  value={`• ${infos.twelveGradesSpecialities?.commentaries?.dropped}`}
                />
              )}
            </div>
            <div className="grid grid-cols-3">
              <DataCell
                subtitle="Option"
                value={
                  infos.twelveGradesSpecialities?.variant
                    ? `• ${infos.twelveGradesSpecialities.variant}`
                    : '• Non renseignée'
                }
              />
              <DataCell
                subtitle="Moyenne"
                value={
                  infos.twelveGradesSpecialities?.averageResults?.variant
                    ? `• ${infos.twelveGradesSpecialities.averageResults.variant}`
                    : '• Non renseignée'
                }
              />
              {infos.twelveGradesSpecialities?.commentaries?.variant && (
                <DataCell
                  subtitle="Remarques"
                  value={`• ${infos.twelveGradesSpecialities?.commentaries?.variant}`}
                />
              )}
            </div>
            <Separator className="mx-auto my-1 w-9/12 bg-black/30" />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="elevenGradesSpecialities" className="border-b-0">
          <AccordionTrigger className="text-small py-2 font-medium text-gray-500 hover:no-underline">
            Spécialités - Première
          </AccordionTrigger>
          <AccordionContent className="w-full space-y-2 pb-0">
            <div
              className={clsx(
                'grid-cols-3',
                infos.initialGradeLevel === 'eleventhGrade' ? 'grid' : 'hidden',
              )}
            >
              <DataCell
                title="Langue apprise"
                value={infos.learnedLanguage === 'english' ? '• Anglais' : '• Français'}
              />
              <DataCell
                title={`Moyenne ${infos.learnedLanguage === 'english' ? 'Anglais' : 'Français'}`}
                value={
                  infos.learnedlanguageAverageResults
                    ? `• ${infos.learnedlanguageAverageResults}`
                    : 'Non renseignée'
                }
              />
              {infos.learnedlanguageCommentaries && (
                <DataCell
                  title="Remarques"
                  value={`• ${infos.learnedlanguageCommentaries}`}
                />
              )}
            </div>
            <div className="grid grid-cols-3">
              <DataCell
                subtitle="Spécialité 1"
                value={
                  infos.elevenGradesSpecialities?.one
                    ? `• ${infos.elevenGradesSpecialities.one}`
                    : '• Non renseignée'
                }
              />
              <DataCell
                subtitle="Moyenne"
                value={
                  infos.elevenGradesSpecialities?.averageResults?.one
                    ? `• ${infos.elevenGradesSpecialities.averageResults.one}`
                    : '• Non renseignée'
                }
              />
              {infos.elevenGradesSpecialities?.commentaries?.one && (
                <DataCell
                  subtitle="Remarques"
                  value={`• ${infos.elevenGradesSpecialities?.commentaries?.one}`}
                />
              )}
            </div>
            <div className="grid grid-cols-3">
              <DataCell
                subtitle="Spécialité 2"
                value={
                  infos.elevenGradesSpecialities?.two
                    ? `• ${infos.elevenGradesSpecialities.two}`
                    : '• Non renseignée'
                }
              />
              <DataCell
                subtitle="Moyenne"
                value={
                  infos.elevenGradesSpecialities?.averageResults?.two
                    ? `• ${infos.elevenGradesSpecialities.averageResults.two}`
                    : '• Non renseignée'
                }
              />
              {infos.elevenGradesSpecialities?.commentaries?.two && (
                <DataCell
                  subtitle="Remarques"
                  value={`• ${infos.elevenGradesSpecialities?.commentaries?.two}`}
                />
              )}
            </div>
            <div className="grid grid-cols-3">
              <DataCell
                subtitle="Spécialité 3"
                value={
                  infos.elevenGradesSpecialities?.three
                    ? `• ${infos.elevenGradesSpecialities.three}`
                    : '• Non renseignée'
                }
              />
              <DataCell
                subtitle="Moyenne"
                value={
                  infos.elevenGradesSpecialities?.averageResults?.three
                    ? `• ${infos.elevenGradesSpecialities.averageResults.three}`
                    : '• Non renseignée'
                }
              />
              {infos.elevenGradesSpecialities?.commentaries?.three && (
                <DataCell
                  subtitle="Remarques"
                  value={`• ${infos.elevenGradesSpecialities?.commentaries?.three}`}
                />
              )}
            </div>
            <Separator className="mx-auto my-1 w-9/12 bg-black/30" />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <DataCell
        title="Motivation"
        mode="textarea"
        value={infos.motivation ? infos.motivation : 'Non renseigné'}
        valueClassName="h-24 text-sm font-light"
      />
      {infos.diplomaSpecialities && infos.diplomaSpecialities.length > 0 && (
        <>
          <Separator className="mx-auto my-1 w-5/12 bg-black/30" />
          <Accordion type="single" collapsible className="mx-2">
            <AccordionItem value="item-1" className="border-b-0">
              <AccordionTrigger className="text-small py-2 font-medium text-gray-500 hover:no-underline">
                Anciennes données
              </AccordionTrigger>
              <AccordionContent>
                <section className="w-auto">
                  {infos.diplomaSpecialities.map((speciality, index) => {
                    if (index === 0)
                      return (
                        <DataCell
                          key={index}
                          subtitle="Spécialités de Première"
                          value={speciality ? `• ${speciality}` : 'Non renseignée'}
                          valueClassName="text-sm font-normal"
                        />
                      );
                    return (
                      <DataCell
                        key={index}
                        value={speciality ? `• ${speciality}` : null}
                        valueClassName="text-sm font-normal"
                      />
                    );
                  })}
                </section>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </>
      )}
    </div>
  );
};
