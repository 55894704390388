import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';

export const DataCell = ({
  value,
  title,
  subtitle,
  mode = 'plain',
  containerClassName = '',
  valueClassName = '',
}: {
  value: string | null;
  title?: string;
  subtitle?: string;
  mode?: 'plain' | 'textfield' | 'textarea';
  containerClassName?: string;
  valueClassName?: string;
}) => {
  if (!value) return null;

  return (
    <div className={cn('mx-2 my-1', containerClassName)}>
      {title && <p className="text-small font-medium text-gray-500">{title}</p>}
      {subtitle && <p className="text-xs font-medium italic text-gray-400">{subtitle}</p>}
      {mode === 'plain' ? (
        <p className={cn('break-all text-lg font-light', valueClassName)}>{value}</p>
      ) : null}
      {mode === 'textfield' ? (
        <Input
          type="text"
          className={cn(
            'w-full rounded-md border border-gray-300 p-1 text-lg',
            valueClassName,
          )}
          value={value}
          readOnly
        />
      ) : null}
      {mode === 'textarea' ? (
        <Textarea
          className={cn(
            'w-full rounded-md border border-gray-300 p-1 text-lg',
            valueClassName,
          )}
          value={value}
          readOnly
        />
      ) : null}
    </div>
  );
};
