import { cva, VariantProps } from 'class-variance-authority';
import { HTMLAttributes } from 'react';

import { cn } from '@/lib/utils';

const asteriskVariants = cva('mx-2', {
  variants: {
    variant: {
      default: 'text-red-500',
    },
    size: {
      default: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export interface AsteriskProps
  extends HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof asteriskVariants> {}

const Asterisk = ({ className, variant, size }: AsteriskProps) => {
  return (
    <span className={cn(asteriskVariants({ variant, size, className }), className)}>
      *
    </span>
  );
};

export { Asterisk, asteriskVariants };
