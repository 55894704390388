import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from '@tanstack/react-router';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { LoaderCircle, Minus, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { DatePicker } from '@/components/date-picker';
import { FormuleSelectCard } from '@/components/formule-cards';
import { SectionSubtitle, SectionTitle } from '@/components/titles';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogDescription, DialogTitle } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { editDocumentFromRecord, editRecord } from '@/data/form-handlers';
import { editReferentSchema, editSecondReferentSchema } from '@/data/forms-schema';
import { db } from '@/lib/firebase';
import { studentSchema } from '@/pages/inscriptions/student/studentInformationSchema';
import {
  FileType,
  Formule,
  FormuleChoice,
  Payment,
  PaymentSchedule,
  Person,
  Referent,
  SchoolRecordEnforced,
  SimplifiedPerson,
  SortedFormules,
  Student,
} from '@/types/types';
import { fileTitleConverter, paymentTitleConverter } from '@/utils/titleConverter';

import { PhoneInput } from '../phone-input';

export const StudentEditModal = ({
  record,
  infos,
  toggleModal,
}: {
  record: SchoolRecordEnforced;
  infos: Student;
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();
  const form = useForm<z.infer<typeof studentSchema>>({
    resolver: zodResolver(studentSchema),
    defaultValues: {
      civility: infos.civility,
      firstname: infos.firstname,
      lastname: infos.lastname,
      //@ts-expect-error -- Firebase envoi un type de Date particulier
      birthdate: new Date(infos.birthdate.toDate()),
      placeOfBirth: infos.placeOfBirth,
      nationality: infos.nationality,
      address: infos.address.street,
      postalCode: infos.address.postalCode,
      city: infos.address.city,
      country: infos.address.country,
      email: infos.contact.email,
      mobilePhone: infos.contact.mobilePhone,
    },
  });
  return (
    <DialogContent className="max-h-[80vh] overflow-auto rounded-md bg-white p-4 lg:max-w-[50%]">
      <DialogTitle className="text-xl font-bold text-previsionblue">
        Modifier les informations de l'etudiant
      </DialogTitle>
      <Form {...form}>
        <form
          className="flex flex-col"
          onSubmit={form.handleSubmit(async (data) => {
            const studentInformations: Student = {
              civility: data.civility,
              firstname: data.firstname,
              lastname: data.lastname,
              birthdate: data.birthdate,
              placeOfBirth: data.placeOfBirth,
              nationality: data.nationality,
              address: {
                street: data.address,
                postalCode: data.postalCode,
                city: data.city,
                country: data.country,
              },
              contact: {
                email: data.email,
                mobilePhone: data.mobilePhone,
              },
            };
            const response = await editRecord(record, 'student', studentInformations);
            if (response) {
              router.invalidate();
              toggleModal('student');
            }
          })}
        >
          <SectionTitle
            className="py-1 text-lg font-semibold"
            title="Informations personnelles"
          />
          <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
            <FormField
              control={form.control}
              name="civility"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Civilité</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue focus:ring-0">
                        <SelectValue placeholder="Selectionnez une civilité" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="M">Monsieur</SelectItem>
                      <SelectItem value="F">Madame</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="firstname"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Prénom</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastname"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Nom</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="birthdate"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Date de naissance</FormLabel>
                  <DatePicker date={field.value} setDate={field.onChange} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="placeOfBirth"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Lieu de naissance</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="nationality"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-end focus-within:text-previsionblue">
                  <FormLabel className="text-base">Nationalité</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <SectionTitle className="py-1 text-lg font-semibold" title="Contact" />
          <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="mb-2 focus-within:text-previsionblue">
                  <FormLabel className="text-base">Adresse mail</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobilePhone"
              render={({ field }) => (
                <FormItem className="mr-2 w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Numéro de téléphone portable
                  </FormLabel>

                  <PhoneInput
                    placeholder="Entrez votre numéro mobile"
                    international
                    defaultCountry="FR"
                    usedIn="modal"
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <SectionTitle className="py-1 text-lg font-semibold" title="Adresse" />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className="mb-2 focus-within:text-previsionblue">
                <FormLabel className="text-base">Adresse (numéro, rue)</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
            <FormField
              control={form.control}
              name="postalCode"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Code postal</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Ville</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Pays</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <Button
            variant="dashboardBlue"
            disabled={form.formState.isSubmitting}
            type="submit"
            className="my-4 w-1/3 self-end"
          >
            {form.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Sauvegarder'
            )}
          </Button>
        </form>
      </Form>
    </DialogContent>
  );
};

export const ReferentEditModal = ({
  record,
  infosFirstReferent,
  toggleModal,
  infosSecondReferent,
}: {
  record: SchoolRecordEnforced;
  infosFirstReferent: Person | Referent;
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
  infosSecondReferent?: SimplifiedPerson;
}) => {
  const [selectValue, setSelectValue] = useState<string>('firstReferent');

  useEffect(() => {
    setSelectValue('firstReferent');
  }, []);

  return (
    <DialogContent className="max-h-[80vh] overflow-auto rounded-md bg-white p-4 lg:max-w-[50%]">
      <DialogTitle className="text-xl font-bold text-previsionblue">
        Modifier les informations des responsables légaux
      </DialogTitle>
      <Select
        defaultValue="firstReferent"
        value={selectValue}
        onValueChange={(value) => setSelectValue(value)}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Responsable légal 1" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="firstReferent">Responsable légal 1</SelectItem>
          <SelectItem value="secondReferent">Responsable légal 2</SelectItem>
        </SelectContent>
      </Select>

      {selectValue === 'firstReferent' && (
        <FirstReferent
          infos={infosFirstReferent}
          record={record}
          toggleModal={toggleModal}
        />
      )}

      {selectValue === 'secondReferent' && (
        <SecondReferent
          infos={infosSecondReferent}
          record={record}
          toggleModal={toggleModal}
        />
      )}
    </DialogContent>
  );
};

const FirstReferent = ({
  infos,
  record,
  toggleModal,
  className,
}: {
  infos: Person | Referent;
  record: SchoolRecordEnforced;
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
  className?: string;
}) => {
  const router = useRouter();
  const formRef1 = useForm<z.infer<typeof editReferentSchema>>({
    resolver: zodResolver(editReferentSchema),
    defaultValues: {
      civility: infos.civility,
      referentFirstName: infos.firstname,
      referentLastName: infos.lastname,
      //@ts-expect-error -- Firebase envoi un type de Date particulier
      referentBirthDate: new Date(infos.birthdate.toDate()),
      referentPlaceOfBirth: infos.placeOfBirth,
      referentEmail: infos.contact.email,
      referentMobilePhone: infos.contact.mobilePhone,
    },
  });

  return (
    <div className={className}>
      <Form {...formRef1}>
        <form
          className="flex flex-col"
          onSubmit={formRef1.handleSubmit(async (data) => {
            const referentInformations: Referent = {
              civility: data.civility,
              firstname: data.referentFirstName,
              lastname: data.referentLastName,
              birthdate: data.referentBirthDate,
              placeOfBirth: data.referentPlaceOfBirth,
              contact: {
                email: data.referentEmail,
                mobilePhone: data.referentMobilePhone,
              },
            };

            const response = await editRecord(record, 'referent', referentInformations);
            if (response) {
              router.invalidate();
              toggleModal('referent');
            }
          })}
        >
          <SectionTitle
            className="py-1 text-lg font-semibold"
            title="Informations personnelles"
          />
          <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
            <FormField
              control={formRef1.control}
              name="civility"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Civilité</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue focus:ring-0">
                        <SelectValue placeholder="Selectionnez une civilité" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="M">Monsieur</SelectItem>
                      <SelectItem value="F">Madame</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={formRef1.control}
              name="referentFirstName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Prénom</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formRef1.control}
              name="referentLastName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Nom</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={formRef1.control}
              name="referentBirthDate"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Date de naissance</FormLabel>
                  <DatePicker date={field.value} setDate={field.onChange} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formRef1.control}
              name="referentPlaceOfBirth"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Lieu de naissance</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <SectionTitle className="py-1 text-lg font-semibold" title="Contact" />
          <section className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
            <FormField
              control={formRef1.control}
              name="referentEmail"
              render={({ field }) => (
                <FormItem className="mb-2 focus-within:text-previsionblue">
                  <FormLabel className="text-base">Adresse mail</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formRef1.control}
              name="referentMobilePhone"
              render={({ field }) => (
                <FormItem className="mr-2 w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Numéro de téléphone portable
                  </FormLabel>
                  <PhoneInput
                    placeholder="Entrez votre numéro mobile"
                    international
                    defaultCountry="FR"
                    usedIn="modal"
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>

          <Button
            variant="dashboardBlue"
            disabled={formRef1.formState.isSubmitting}
            type="submit"
            className="my-4 w-full self-end"
          >
            {formRef1.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Sauvegarder'
            )}
          </Button>
        </form>
      </Form>
    </div>
  );
};

const SecondReferent = ({
  record,
  toggleModal,
  className,
  infos,
}: {
  record: SchoolRecordEnforced;
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
  className?: string;
  infos?: SimplifiedPerson;
}) => {
  const router = useRouter();
  const formRef2 = useForm<z.infer<typeof editSecondReferentSchema>>({
    resolver: zodResolver(editSecondReferentSchema),
    defaultValues: {
      secondReferentCivility: infos ? infos.civility : 'M',
      secondReferentFirstName: infos ? infos.firstname : '',
      secondReferentLastName: infos ? infos.lastname : '',
      secondReferentEmail: infos ? infos.contact.email : '',
      secondReferentMobilePhone: infos ? infos.contact.mobilePhone : '',
    },
  });

  return (
    <div className={className}>
      <Form {...formRef2}>
        <form
          className="flex flex-col"
          onSubmit={formRef2.handleSubmit(async (data) => {
            const referentInformations: SimplifiedPerson = {
              civility: data.secondReferentCivility,
              firstname: data.secondReferentFirstName,
              lastname: data.secondReferentLastName,
              contact: {
                email: data.secondReferentEmail,
                mobilePhone: data.secondReferentMobilePhone,
              },
            };

            const response = await editRecord(
              record,
              'secondReferent',
              referentInformations,
            );

            if (response) {
              router.invalidate();
              toggleModal('referent');
            }
          })}
        >
          <SectionTitle
            className="py-1 text-lg font-semibold"
            title="Informations personnelles"
          />
          <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-6">
            <FormField
              control={formRef2.control}
              name="secondReferentCivility"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Civilité</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue focus:ring-0">
                        <SelectValue placeholder="Selectionnez une civilité" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="M">Monsieur</SelectItem>
                      <SelectItem value="F">Madame</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={formRef2.control}
              name="secondReferentFirstName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Prénom</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formRef2.control}
              name="secondReferentLastName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">Nom</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <SectionTitle className="py-1 text-lg font-semibold" title="Contact" />
          <FormField
            control={formRef2.control}
            name="secondReferentEmail"
            render={({ field }) => (
              <FormItem className="mb-2 focus-within:text-previsionblue">
                <FormLabel className="text-base">Adresse mail</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-6">
            <FormField
              control={formRef2.control}
              name="secondReferentMobilePhone"
              render={({ field }) => (
                <FormItem className="mr-2 w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Numéro de téléphone portable
                  </FormLabel>
                  <PhoneInput
                    placeholder="Entrez votre numéro mobile"
                    international
                    defaultCountry="FR"
                    usedIn="modal"
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>

          <Button
            variant="dashboardBlue"
            disabled={formRef2.formState.isSubmitting}
            type="submit"
            className="my-4 w-full self-end"
          >
            {formRef2.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Sauvegarder'
            )}
          </Button>
        </form>
      </Form>
    </div>
  );
};

export const FormuleEditModal = ({
  record,
  infos,
  toggleModal,
}: {
  record: SchoolRecordEnforced;
  infos: Formule;
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();
  const [cleanedData, setCleanedData] = useState<SortedFormules>({});
  const [choosedYear, setChoosedYear] = useState<string>('');
  const [choosedType, setChoosedType] = useState<string>('');
  const [defaultFormule] = useState<FormuleChoice | null>(record.formule);

  const [selectedFormule, setSelectedFormule] = useState<Formule | null>(infos);

  useEffect(() => {
    const getFormuleSpecs = async () => {
      const colRef = collection(db, 'formules');
      const firebaseQuery = query(colRef, where('status', '==', 'published'));
      const querySnapshot = await getDocs(firebaseQuery);
      const data: Formule[] = [];

      querySnapshot.forEach((doc) => {
        if (!doc.exists()) return null;
        data.push({
          id: doc.id as string,
          ...doc.data(),
        } as Formule);
      });

      const cleanedData: SortedFormules = data.reduce((acc: SortedFormules, objet) => {
        const { year, type, ...data } = objet;
        acc[year] = acc[year] || {};
        acc[year][type] = acc[year][type] || [];

        // @ts-expect-error -- ID ne sera jamais null
        acc[year][type].push({
          ...data,
          year,
          type,
        });
        return acc;
      }, {});

      //@ts-expect-error - Object cannot be undefined
      setChoosedYear(Object.keys(cleanedData)[0]);
      //@ts-expect-error - Object cannot be undefined
      setChoosedType(Object.keys(cleanedData[Object.keys(cleanedData)[0]])[0]);
      setCleanedData(cleanedData);
    };

    getFormuleSpecs();
  }, []);

  if (Object.keys(cleanedData).length === 0)
    return (
      <DialogContent className="flex justify-center">
        <LoaderCircle size={32} className="animate-spin text-previsionorange" />
      </DialogContent>
    );

  return (
    <DialogContent className="max-h-[65vh] overflow-auto rounded-md bg-white p-4 lg:max-w-[50%]">
      <DialogTitle className="text-xl font-bold text-previsionblue">
        Modifier la formule
      </DialogTitle>
      <form
        className="flex w-full flex-col"
        onSubmit={async (e) => {
          e.preventDefault();
          if (!selectedFormule || !selectedFormule.id) return;

          const oldRecord = record;

          const formuleInformations = {
            formuleReference: doc(db, 'formules', selectedFormule?.id),
            formuleId: selectedFormule?.id,
            cost: parseFloat(selectedFormule?.price),
            year: selectedFormule?.year,
            title: selectedFormule?.title,
          };

          const payment = {
            ...record.payments,
            totalAmount: parseFloat(selectedFormule.price) * 100,
            totalReduction: parseFloat(selectedFormule.reduction) * 100,
            schedule:
              record.payments && record.payments.schedule
                ? [
                    ...record.payments.schedule.slice(0, -1),
                    {
                      ...record.payments.schedule[record.payments.schedule.length - 1],
                      paymentAmount: parseFloat(selectedFormule.price) * 100,
                      paymentReduction: parseFloat(selectedFormule.reduction) * 100,
                    },
                  ]
                : undefined,
          };

          if (
            JSON.stringify(formuleInformations) === JSON.stringify(oldRecord.formule) &&
            JSON.stringify(payment) === JSON.stringify(oldRecord.payments)
          ) {
            return;
          }

          const response = await editRecord(record, 'formule', formuleInformations);
          if (response) {
            const updatePaymentCalendar = await editRecord(
              {
                ...record,
                formule: formuleInformations,
              },
              'payments',
              //@ts-expect-error - Object cannot be undefined
              payment,
            );

            if (updatePaymentCalendar) {
              router.invalidate();
              toggleModal('scolarity');
            } else {
              console.error('Error while updating payment calendar');
              await editRecord(oldRecord, 'formule', oldRecord.formule);
            }
          }
        }}
      >
        <div className="mb-6 grid grid-cols-2 gap-4">
          <Select
            onValueChange={(value) => setChoosedYear(value)}
            value={choosedYear}
            defaultValue={defaultFormule?.year ?? Object.keys(cleanedData)[0] ?? ''}
          >
            <SelectTrigger>
              <SelectValue placeholder="Selectionnez une année" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {Object.keys(cleanedData).map((year) => (
                  <SelectItem key={year} value={year}>
                    {year}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select
            disabled={!choosedYear}
            onValueChange={(value) => setChoosedType(value)}
            value={choosedType}
            defaultValue={Object.keys(cleanedData[choosedYear] || 0)[0] || ''}
          >
            <SelectTrigger>
              <SelectValue placeholder="Selectionnez une préparation" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {choosedYear &&
                  //@ts-expect-error - Object cannot be undefined
                  Object.keys(cleanedData[choosedYear]).map((type) => (
                    <SelectItem key={type} value={type}>
                      {type}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {!choosedType && (
          <section className="flex h-32 w-full items-center justify-center rounded-sm bg-black/15">
            <p className="text-base font-semibold text-black/50">
              Veuillez choisir une année et une préparation
            </p>
          </section>
        )}
        {choosedType &&
          choosedYear &&
          //@ts-expect-error - Object cannot be undefined
          cleanedData[choosedYear][choosedType].length === 0 && (
            <section className="flex h-32 w-full items-center justify-center rounded-sm bg-black/15">
              <p className="text-base font-semibold text-black/50">
                Aucune préparation n'est disponible pour cette année
              </p>
            </section>
          )}
        {choosedType &&
          choosedYear &&
          //@ts-expect-error - Object cannot be undefined
          cleanedData[choosedYear][choosedType].length > 0 && (
            <section className="flex min-h-32 flex-col space-y-4">
              {/* @ts-expect-error - Object cannot be undefined */}
              {cleanedData[choosedYear][choosedType].map((formule) => (
                <FormuleSelectCard
                  key={formule.id}
                  title={formule.title}
                  price={formule.price}
                  description={formule.description}
                  currentSelectedTitle={selectedFormule?.title || ''}
                  selectFunction={() => setSelectedFormule(formule)}
                />
              ))}
            </section>
          )}

        <Button variant="dashboardBlue" type="submit" className="my-4 w-1/3 self-end">
          Sauvegarder
        </Button>
      </form>
    </DialogContent>
  );
};

export const FileEditModal = ({
  record,
  toggleModal,
}: {
  record: SchoolRecordEnforced;
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();

  const types = [
    'identityPicture',
    'identityDocument',
    'scolarityElevenGradeReportOne',
    'scolarityElevenGradeReportTwo',
    'scolarityElevenGradeReportThree',
    'scolarityReportOne',
    'scolarityReportTwo',
    'scolarityReportThree',
    'HighSchoolDiplomaGrades',
  ] as FileType[];

  //@ts-expect-error - Object cannot be undefined
  const [selectedFileType, setSelectedFileType] = useState<FileType>(types[0]);

  return (
    <DialogContent>
      <DialogTitle className="text-xl font-bold text-previsionblue">
        Modifier les documents
      </DialogTitle>
      <SectionSubtitle
        className="py-0 text-sm font-normal italic text-red-600"
        title={`Attention, si vous chargez un nouveau fichier, l'ancien fichier sera écrasé par le nouveau fichier uploadé.`}
      />
      <Select
        value={selectedFileType}
        onValueChange={(value: FileType) => setSelectedFileType(value)}
      >
        <SelectTrigger className="">
          <SelectValue placeholder="Type du fichier" />
        </SelectTrigger>
        <SelectContent>
          {types.map((type) => (
            <SelectItem key={type} value={type}>
              {fileTitleConverter(type)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <form
        className="flex flex-col"
        onSubmit={(e) => {
          e.preventDefault();
          router.invalidate();
          toggleModal('files');
        }}
      >
        <section className="flex w-full flex-col">
          {types.map((type) => {
            if (type !== selectedFileType) return null;
            return <FileInput key={type} fileType={type} record={record} />;
          })}
        </section>
        <Button variant="dashboardBlue" type="submit" className="my-4 w-1/3 self-end">
          Fermer
        </Button>
      </form>
    </DialogContent>
  );
};

const FileInput = ({
  fileType,
  record,
}: {
  fileType: FileType;
  record: SchoolRecordEnforced;
}) => {
  return (
    <FormItem className="my-1 focus-within:text-previsionblue">
      <p className="text-base font-bold text-previsionblue">
        {fileTitleConverter(fileType)}
      </p>
      <Input
        type="file"
        accept="image/*, application/pdf"
        onChange={(event) => {
          if (!event.target.files) return;
          if (event.target.files[0]) {
            editDocumentFromRecord(
              record,
              event.target.files && event.target.files[0],
              fileType,
            );
          }
        }}
      />
    </FormItem>
  );
};

export const PaymentCalendarEditModal = ({
  record,
  infos,
  toggleModal,
}: {
  record: SchoolRecordEnforced;
  infos: Payment;
  toggleModal: (
    from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files' | 'payments',
  ) => void;
}) => {
  const router = useRouter();

  const [baseCalendar, setBaseCalendar] = useState<PaymentSchedule[]>(
    infos.schedule || [],
  );
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (infos.schedule) {
      setBaseCalendar(infos.schedule);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePaymentCalendar = async () => {
    setLoading(true);

    if (
      baseCalendar.some((payment) => {
        return payment.paymentType === '' || payment.paymentAmount === 0;
      })
    ) {
      setLoading(false);
      return toast('Veuillez remplir tous les champs');
    }

    const newTotalPaid = baseCalendar.reduce((acc, payment, index, array) => {
      if (payment.paymentStatus === 'paid') {
        if (index === array.length - 1) {
          return payment.paymentAmount;
        }
        return acc + payment.paymentAmount;
      }
      return acc;
    }, 0);

    const newPayment: Payment = {
      ...infos,
      totalPaid: newTotalPaid,
      schedule: baseCalendar,
    };

    let alteredOldRecord = {
      ...record,
    };

    //Si le paiement accompte de baseCalendar est egal a paid et que le paiement accompte de record est egal a pending
    if (
      baseCalendar.find((payment) => payment.paymentType === 'accompte')
        ?.paymentStatus === 'paid' &&
      infos.schedule?.find((payment) => payment.paymentType === 'accompte')
        ?.paymentStatus === 'pending'
    ) {
      alteredOldRecord = {
        ...record,
        recordStatus: 'submitted',
      };
    }

    const response = await editRecord(alteredOldRecord, 'payments', newPayment);
    if (response) {
      router.invalidate();

      setTimeout(() => {
        toast('Echéancier de paiement sauvegardé avec succès');
        setLoading(false);
        toggleModal('payments');
      }, 2000);
      return;
    } else {
      return toast("Erreur lors de la sauvegarde de l'échéancier de paiement");
    }
  };

  const removeItem = (index: number) => {
    const newCalendar = baseCalendar.filter((_, i) => i !== index);
    setBaseCalendar(newCalendar);
  };

  const addItemBefore = (index: number) => {
    const newCalendar = [...baseCalendar];
    const newItem: PaymentSchedule = {
      paymentType: '',
      paymentReduction: 0,
      paymentAmount: 0,
      paymentStatus: 'pending',
      paymentMethod: null,
      paymentDate: null,
      paymentReference: null,
    };
    newCalendar.splice(index - 1, 0, newItem);
    setBaseCalendar(newCalendar);
  };

  return (
    <DialogContent className="max-w-[98%] rounded-md bg-white p-4 lg:max-w-[80%]">
      <DialogTitle className="text-xl font-bold text-previsionblue">
        Modifier l'échéancier de paiement
      </DialogTitle>
      <div>
        <div className="flex w-full text-base font-bold text-black/40 [&>*]:mr-2 [&>*]:w-1/5 lg:[&>*]:w-1/6">
          <div>Mois</div>
          <div>Réduction</div>
          <div>Total (€)</div>
          <div className="mr-2 !max-w-16">Payé</div>
          <div>Methode de paiement</div>
        </div>
        {baseCalendar.map((payment, index) => {
          const isSwitchChecked = payment.paymentStatus === 'paid';
          return (
            <div
              key={index}
              className="my-2 flex w-full text-base text-black/60 [&>*]:w-1/5 lg:[&>*]:w-1/6"
            >
              <div className="mr-2">
                <Input
                  type="text"
                  value={paymentTitleConverter(payment.paymentType)}
                  //defaultValue={paymentTitleConverter(payment.paymentType)}
                  onChange={(e) => {
                    const newCalendar = baseCalendar.map((payment, i) => {
                      if (i === index) {
                        return {
                          ...payment,
                          paymentType: e.target.value,
                        };
                      }
                      return payment;
                    });

                    setBaseCalendar(newCalendar);
                  }}
                />
              </div>
              <div className="mr-2">
                <Input
                  type="number"
                  value={payment.paymentReduction}
                  //defaultValue={payment.paymentReduction}
                  onChange={(e) => {
                    const newCalendar = baseCalendar.map((payment, i) => {
                      if (i === index) {
                        return {
                          ...payment,
                          paymentReduction: e.target.value,
                        };
                      }
                      return payment;
                    });

                    // @ts-expect-error -- We know that the calendar is an array of PaymentSchedule
                    setBaseCalendar(newCalendar);
                  }}
                />
              </div>
              <div className="mr-2">
                <Input
                  type="number"
                  defaultValue={payment.paymentAmount ? payment.paymentAmount / 100 : 0}
                  onChange={(e) => {
                    const newCalendar = baseCalendar.map((payment, i) => {
                      if (i === index) {
                        return {
                          ...payment,
                          paymentAmount: parseInt(e.target.value) * 100,
                        };
                      }
                      return payment;
                    });

                    setBaseCalendar(newCalendar);
                  }}
                />
              </div>
              <div className="mr-2 !max-w-16">
                <Switch
                  className="data-[state=checked]:bg-previsionblue"
                  checked={isSwitchChecked}
                  onCheckedChange={(e) => {
                    const newCalendar = baseCalendar.map((payment, i) => {
                      if (i === index) {
                        return {
                          ...payment,
                          paymentStatus: e ? 'paid' : 'pending',
                        };
                      }
                      return payment;
                    });
                    // @ts-expect-error -- We know that the calendar is an array of PaymentSchedule
                    setBaseCalendar(newCalendar);
                  }}
                />
              </div>
              <Select
                disabled={!isSwitchChecked}
                onValueChange={(e) => {
                  const newCalendar = baseCalendar.map((payment, i) => {
                    if (i === index) {
                      return {
                        ...payment,
                        paymentMethod: e,
                      };
                    }
                    return payment;
                  });

                  setBaseCalendar(newCalendar);
                }}
                defaultValue={payment.paymentMethod || 'credit-card'}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Selectionner une méthode de paiement" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="cheque">Chèque</SelectItem>
                    <SelectItem value="cash">Espèces</SelectItem>
                    <SelectItem value="bank-transfer">Virement bancaire</SelectItem>
                    <SelectItem value="credit-card">Carte bancaire</SelectItem>
                    <SelectItem value="bank-debit">Prélèvement</SelectItem>
                    <SelectItem value="stripe">Stripe</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <div className="hidden justify-center lg:flex">
                <Button
                  className="mx-2"
                  variant="outline"
                  onClick={() => addItemBefore(index)}
                >
                  <Plus />
                </Button>
                <Button
                  className="mx-2"
                  variant="outline"
                  onClick={() => removeItem(index)}
                >
                  <Minus />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Button
          variant="dashboardBlue"
          onClick={(e) => {
            e.preventDefault();
            setConfirmModal(true);
          }}
          disabled={loading}
          className="w-full"
        >
          {loading ? <LoaderCircle className="animate-spin" /> : 'Sauvegarder'}
        </Button>
      </div>
      <AlertDialog
        open={confirmModal}
        onOpenChange={(open: boolean) => setConfirmModal(open)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Etes-vous sûr de vouloir sauvegarder ces modifications ?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Cela écrasera les données actuelles de l'échéancier de paiement, et est
              définitif.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="rounded-none border border-black/60 hover:border-previsionblue/75">
              Annuler
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                updatePaymentCalendar();
              }}
              className="rounded-none bg-previsionblue text-white hover:bg-previsionblue/75"
            >
              Continuer
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </DialogContent>
  );
};

export const ErrorModal = ({ error }: { error: string }) => {
  return (
    <DialogContent>
      <DialogTitle>Erreur</DialogTitle>
      <DialogContent>
        <DialogDescription>{error}</DialogDescription>
      </DialogContent>
    </DialogContent>
  );
};
