import { useLoaderData } from '@tanstack/react-router';
import { useState } from 'react';

import { FormulesInformations } from '@/components/record/data-section/FormulesInformations';
import { RecordNoteBlock } from '@/components/record/data-section/NoteBlock';
import { PastScolarityBlock } from '@/components/record/data-section/past-scolarity/PastScolarityBlock';
import { PaymentCalendar } from '@/components/record/data-section/PaymentCalendar';
import { RecordHeader } from '@/components/record/data-section/RecordHeader';
import { ReferentInformations } from '@/components/record/data-section/ReferentInformations';
import { StudentDocuments } from '@/components/record/data-section/StudentDocuments';
import { StudentsInformations } from '@/components/record/data-section/StudentInformations';
import Timeline from '@/components/record/data-section/timeline/Timeline';
import {
  ErrorModal,
  FileEditModal,
  FormuleEditModal,
  PaymentCalendarEditModal,
  ReferentEditModal,
  StudentEditModal,
} from '@/components/record/edit-modal';
import { Dialog } from '@/components/ui/dialog';
import {
  Formule,
  FormuleChoice,
  Payment,
  Person,
  RecordFile,
  Referent,
  SchoolRecordEnforced,
  Scolarity,
  SimplifiedPerson,
  Student,
  TimelineType,
} from '@/types/types';

export const InscriptionDetailsPage = () => {
  const [openedEditModal, setOpenedEditModal] = useState(false);
  const [sectionTypeModal, setSectionTypeModal] = useState<
    'student' | 'referent' | 'formule' | 'scolarity' | 'files' | 'payments' | null
  >(null);
  const [key, setKey] = useState(Math.random());

  const {
    data,
    formuleData,
  }: {
    data: SchoolRecordEnforced;
    formuleData: Formule;
  } = useLoaderData({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const {
    student,
    referent,
    secondReferent,
    formule,
    scolarity,
    files,
    /* timeline, */
    payments,
    recordNote,
  }: {
    student: Student;
    referent: Referent | Person;
    secondReferent?: SimplifiedPerson;
    formule: FormuleChoice;
    scolarity: Scolarity;
    files: RecordFile[];
    timeline: TimelineType[];
    payments: Payment;
    recordNote: string;
  } = data;
  const { lastname, firstname } = student;

  const toggleModal = (
    from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files' | 'payments',
  ) => {
    setOpenedEditModal(!openedEditModal);
    setSectionTypeModal(from);
  };

  return (
    <section className="w-full bg-previsiongrey py-2 md:w-[calc(100%-20rem)] lg:px-6">
      <RecordHeader
        lastname={lastname}
        firstname={firstname}
        refundStatus={data.payments?.refunded || false}
        //@ts-expect-error - Object cannot be undefined
        depositStatus={payments?.schedule ? payments.schedule[0].paymentStatus : ''}
        formationCost={data.formule?.cost || 0}
        isRecordValidated={data.isRecordValidated || false}
        previousSchoolRecord={data}
        year={formule?.year}
      />
      <div className="mx-2 flex flex-col py-4 lg:flex-row lg:justify-between">
        <div className="w-full space-y-4 lg:m-2 lg:w-2/3">
          {student && <StudentsInformations infos={student} triggerEdit={toggleModal} />}
          {referent && (
            <ReferentInformations
              referent={referent}
              secondReferent={secondReferent}
              triggerEdit={toggleModal}
            />
          )}
          {formule && (
            <FormulesInformations infos={formuleData} triggerEdit={toggleModal} />
          )}
          {scolarity && (
            <PastScolarityBlock
              infos={scolarity}
              studentInfos={student}
              triggerEdit={toggleModal}
            />
          )}
          {payments && data.recordStatus === 'submitted' && (
            <PaymentCalendar infos={payments} triggerEdit={toggleModal} />
          )}
        </div>
        <div className="my-4 w-full space-y-4 lg:m-2 lg:my-2 lg:w-1/3">
          <Timeline data={data} />
          <RecordNoteBlock previousNote={recordNote} record={data} />
          {files && <StudentDocuments files={files} triggerEdit={toggleModal} />}
        </div>
      </div>
      <Dialog
        open={openedEditModal}
        onOpenChange={(value) => {
          setOpenedEditModal(value);
          if (!value) {
            // Si la boîte de dialogue est fermée, générer une nouvelle clé
            setKey(Math.random());
          }
        }}
      >
        {sectionTypeModal === null && (
          <ErrorModal error="Erreur de l'application, veuillez réessayer" />
        )}
        {sectionTypeModal === 'student' && (
          <StudentEditModal record={data} infos={student} toggleModal={toggleModal} />
        )}
        {sectionTypeModal === 'referent' && (
          <ReferentEditModal
            key={key}
            record={data}
            infosFirstReferent={referent}
            infosSecondReferent={secondReferent}
            toggleModal={toggleModal}
          />
        )}
        {sectionTypeModal === 'formule' && (
          <FormuleEditModal record={data} infos={formuleData} toggleModal={toggleModal} />
        )}
        {sectionTypeModal === 'files' && (
          <FileEditModal record={data} /* infos={files} */ toggleModal={toggleModal} />
        )}
        {sectionTypeModal === 'payments' && (
          <PaymentCalendarEditModal
            key={key}
            record={data}
            infos={payments}
            toggleModal={toggleModal}
          />
        )}
      </Dialog>
    </section>
  );
};
