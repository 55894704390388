import { formatDate } from 'date-fns';
import { httpsCallable } from 'firebase/functions';

import { functions } from '@/lib/firebase';

export const downloadGeneratedCSV = async (
  recordStatus: {
    pending: boolean;
    submitted: boolean;
    cancelled: boolean;
    archived: boolean;
    deleted: boolean;
  },
  scholarYear: string,
  formuleType: string,
  onlyValidated: boolean,
  onlyRefunded: boolean,
  selectedFields: {
    student: boolean;
    referent: boolean;
    pastScolarity: boolean;
    formule: boolean;
    payment: boolean;
  },
  isDashAdminFormat = false,
  numberOfRecords = -1,
) => {
  try {
    const getCSV = httpsCallable(functions, 'generateCSVFile');

    const response = await getCSV({
      recordStatus,
      scholarYear,
      formuleType,
      onlyValidated,
      onlyRefunded,
      isDashAdminFormat,
      selectedFields,
      numberOfRecords,
    });

    let exportFileStatusName = '';
    const element = document.createElement('a');

    if (
      recordStatus.pending &&
      recordStatus.submitted &&
      recordStatus.cancelled &&
      recordStatus.archived &&
      recordStatus.deleted
    ) {
      exportFileStatusName = 'all';
    } else {
      if (recordStatus.pending) {
        exportFileStatusName += 'pending_';
      }
      if (recordStatus.submitted) {
        exportFileStatusName += 'submitted_';
      }
      if (recordStatus.cancelled) {
        exportFileStatusName += 'cancelled_';
      }
      if (recordStatus.archived) {
        exportFileStatusName += 'archived_';
      }
      if (recordStatus.deleted) {
        exportFileStatusName += 'deleted_';
      }
    }

    element.setAttribute(
      'href',
      // @ts-expect-error -- Typescript doesn't know about the response type
      `data:text/csv;charset=utf-8,${encodeURI(response.data)}`,
    );
    element.setAttribute(
      'download',
      `inscriptions_prevision_${formatDate(new Date(), 'dd-MM-yyyy_HH-mm-ss')}_${exportFileStatusName}.csv`,
    );
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (error) {
    console.error('Error while downloading CSV:', error);
    throw new Error('Error while downloading CSV');
  }
};
