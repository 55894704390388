import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useLoaderData, useNavigate } from '@tanstack/react-router';
import clsx from 'clsx';
import { LoaderCircle, Minus, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Asterisk } from '@/components/asterisk';
import { DatePicker } from '@/components/date-picker';
import { PhoneInput } from '@/components/phone-input';
import { SectionSubtitle, SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { referentSchema } from '@/pages/inscriptions/referent/referentInformationSchema';
import { stepTwoSubmit } from '@/pages/inscriptions/referent/referentInformationSubmit';
import { SchoolRecord } from '@/types/types';

export const ReferentInformations = () => {
  const navigate = useNavigate();

  const {
    pendingInscription,
  }: {
    pendingInscription: SchoolRecord | null;
    // @ts-expect-error --  Les types de la propriété strict sont incompatibles.(Impossible d'assigner le type false au type true)
  } = useLoaderData({
    from: '/steps',
    strict: false,
  });

  const form = useForm<z.infer<typeof referentSchema>>({
    resolver: zodResolver(referentSchema),
    defaultValues: {
      hasSecondReferent: pendingInscription?.secondReferent ? true : false,
      civility: pendingInscription?.referent?.civility ?? 'M',
      referentFirstName: pendingInscription?.referent?.firstname ?? '',
      referentLastName: pendingInscription?.referent?.lastname ?? '',
      referentBirthDate: pendingInscription?.referent?.birthdate
        ? new Date(pendingInscription?.referent?.birthdate)
        : new Date(),
      referentPlaceOfBirth: pendingInscription?.referent?.placeOfBirth ?? '',
      hiddedStudentEmail: pendingInscription?.student?.contact?.email ?? '',
      hiddedStudentMobilePhone: pendingInscription?.student?.contact?.mobilePhone ?? '',
      referentEmail: pendingInscription?.referent?.contact?.email ?? '',
      referentMobilePhone: pendingInscription?.referent?.contact?.mobilePhone ?? '',
      secondReferentCivility: pendingInscription?.secondReferent?.civility ?? undefined,
      secondReferentFirstName: pendingInscription?.secondReferent?.firstname ?? undefined,
      secondReferentLastName: pendingInscription?.secondReferent?.lastname ?? undefined,
      secondReferentEmail:
        pendingInscription?.secondReferent?.contact?.email ?? undefined,
      secondReferentMobilePhone:
        pendingInscription?.secondReferent?.contact?.mobilePhone ?? undefined,
    },
  });

  const [withSecondReferent, setWithSecondReferent] = useState(
    !!pendingInscription?.secondReferent,
  );

  useEffect(() => {
    if (form) {
      form.setValue(
        'hiddedStudentEmail',
        pendingInscription?.student?.contact?.email ?? '',
      );

      form.setValue(
        'hiddedStudentMobilePhone',
        pendingInscription?.student?.contact?.mobilePhone ?? '',
      );
    }
  }, [
    form,
    pendingInscription?.student?.contact?.email,
    pendingInscription?.student?.contact?.mobilePhone,
  ]);

  useEffect(() => {
    if (form && withSecondReferent) {
      form.setValue('hasSecondReferent', true);
    } else {
      form.setValue('hasSecondReferent', false);
    }
  }, [form, withSecondReferent]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const response = await stepTwoSubmit(data);

          //Wait 3s before redirecting to the next step
          await new Promise((resolve) => setTimeout(resolve, 3000));

          if (response) {
            navigate({
              to: '/steps/three',
            });
          } else {
            console.error('Error adding document');
          }
        })}
        className="h-fit w-full bg-white px-7 py-4 text-black/60"
      >
        <SectionTitle title="Coordonnées des responsables légaux" />
        <div>
          <SectionSubtitle title="Responsable légal 1" />
          <FormDescription className="py-2">
            Cette personne sera prévenue en cas d'urgence.
          </FormDescription>
          <section className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
            <FormField
              control={form.control}
              name="civility"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Civilité
                    <Asterisk />
                  </FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue focus:ring-0">
                        <SelectValue placeholder="Selectionnez une civilité" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="M">Monsieur</SelectItem>
                      <SelectItem value="F">Madame</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="referentFirstName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Prénom
                    <Asterisk />
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="referentLastName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Nom de famille
                    <Asterisk />
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="referentBirthDate"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Date de naissance
                    <Asterisk />
                  </FormLabel>
                  <DatePicker date={field.value} setDate={field.onChange} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="referentPlaceOfBirth"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Lieu de naissance
                    <Asterisk />
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>

          <section className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
            <FormField
              control={form.control}
              name="hiddedStudentEmail"
              render={({ field }) => (
                <FormItem
                  className="mb-2 hidden focus-within:text-previsionblue"
                  aria-hidden="true"
                >
                  <FormLabel className="text-base">Adresse mail de l'élève</FormLabel>
                  <Input {...field} variant="prevision" readOnly />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="hiddedStudentMobilePhone"
              render={({ field }) => (
                <FormItem
                  className="hidden focus-within:text-previsionblue"
                  aria-hidden="true"
                >
                  <FormLabel className="text-base">
                    Numéro de téléphone de l'élève
                  </FormLabel>
                  <Input {...field} variant="prevision" readOnly />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="referentEmail"
              render={({ field }) => (
                <FormItem className="mb-2 focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Adresse mail
                    <Asterisk />
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormDescription className="text-xs">
                    Attention, le courriel doit être valide afin de vous envoyer les
                    informations administratives.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="referentMobilePhone"
              render={({ field }) => (
                <FormItem className=" focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Numéro de téléphone portable
                    <Asterisk />
                  </FormLabel>
                  <PhoneInput
                    placeholder="Entrez votre numéro mobile"
                    international
                    defaultCountry="FR"
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
        </div>
        <div>
          <SectionSubtitle title="Responsable légal 2 - Optionnel" />
          <div className="mb-4">
            <Button
              variant={'outlinedBlue'}
              type="button"
              onClick={() => {
                setWithSecondReferent(!withSecondReferent);
              }}
            >
              {withSecondReferent ? (
                <>
                  <Minus /> Retirer le second responsable légal
                </>
              ) : (
                <>
                  <Plus /> Ajouter un second responsable légal
                </>
              )}
            </Button>
          </div>
          <section
            className={clsx(
              'mb-4 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6',
              withSecondReferent ? 'block' : 'hidden',
            )}
          >
            <FormField
              control={form.control}
              name="secondReferentCivility"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Civilité
                    <Asterisk />
                  </FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue focus:ring-0">
                        <SelectValue placeholder="Selectionnez une civilité" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="M">Monsieur</SelectItem>
                      <SelectItem value="F">Madame</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="secondReferentFirstName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Prénom
                    <Asterisk />
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="secondReferentLastName"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Nom de famille
                    <Asterisk />
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="secondReferentEmail"
              render={({ field }) => (
                <FormItem className="focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Adresse mail
                    <Asterisk />
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormDescription className="text-xs">
                    Attention, le courriel doit être valide afin de vous envoyer les
                    informations administratives.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="secondReferentMobilePhone"
              render={({ field }) => (
                <FormItem className=" focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Numéro de téléphone portable
                    <Asterisk />
                  </FormLabel>
                  <PhoneInput
                    placeholder="Entrez votre numéro mobile"
                    international
                    defaultCountry="FR"
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
        </div>
        <div className="flex justify-between">
          <Link to={`/steps/one`}>
            <Button type="button" variant="outlinedBlue" className="w-32">
              Précédent
            </Button>
          </Link>
          <Button
            type="submit"
            variant="squaredBlue"
            disabled={form.formState.isSubmitting}
            className="w-32"
          >
            {form.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Suivant'
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};
