import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import logo from '@/assets/images/prevision-logo.png';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { loginSubmit } from '@/data/form-handlers';
import { loginSchema } from '@/data/forms-schema';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { origin } = useSearch({
    from: '/login',
  });

  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-previsiongrey">
      <Card className="w-11/12 flex-col bg-white lg:w-2/4">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(async (data) => {
              try {
                await loginSubmit(data.email, data.password);
                const redirectPath = origin || '/admin/inscriptions/pending';

                navigate({
                  // @ts-expect-error -- redirect path sera toujours correct mais ne peux pas etre typé
                  to: redirectPath,
                });
              } catch (error) {
                console.error(error);

                if (error instanceof Error) {
                  if (error.message.includes('auth/invalid-credential')) {
                    form.setError('root.form', {
                      type: '401',
                      message: 'Identifiants invalides. Veuillez réessayer.',
                    });
                  } else {
                    form.setError('root.form', {
                      type: 'unknown',
                      message: error.message,
                    });
                  }
                } else {
                  form.setError('root.form', {
                    type: 'unknown',
                    message: 'Une erreur est survenue. Veuillez réessayer.',
                  });
                }
              }
            })}
          >
            <CardHeader className="flex flex-row justify-between">
              <div>
                <CardTitle>Connexion</CardTitle>
                <CardDescription>Merci d'entrer vos identifiants</CardDescription>
              </div>
              <img src={logo} alt="logo" className="hidden w-1/3 lg:block" />
            </CardHeader>
            <CardContent className="space-y-2">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="focus-within:text-previsionblue">
                    <FormLabel className="text-base">Email</FormLabel>
                    <FormControl>
                      <Input
                        variant="prevision"
                        placeholder="mail@domaine.fr"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="focus-within:text-previsionblue">
                    <FormLabel className="text-base">Mot de passe</FormLabel>
                    <FormControl>
                      <Input variant="prevision" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.formState.errors.root?.form && (
                <p className="text-end text-sm font-semibold text-red-500">
                  {form.formState.errors.root.form.message}
                </p>
              )}
            </CardContent>
            <CardFooter>
              <Button
                type="submit"
                variant="dashboardBlue"
                className="mt-2 w-full min-w-32 lg:ml-auto lg:mr-0 lg:flex lg:w-auto"
                disabled={form.formState.isSubmitting}
              >
                {form.formState.isSubmitting ? (
                  <LoaderCircle className="animate-spin" />
                ) : (
                  'Se connecter'
                )}
              </Button>
            </CardFooter>
          </form>
        </Form>
      </Card>
    </main>
  );
};
