import { formatDate } from 'date-fns';
import { SquarePen } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { DataCell } from '@/components/ui/record-cell';
import { Person, Referent, SimplifiedPerson } from '@/types/types';

export const ReferentInformations = ({
  referent,
  triggerEdit,
  secondReferent,
}: {
  referent: Referent | Person;
  triggerEdit: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
  secondReferent?: SimplifiedPerson;
}) => {
  return (
    <div className="space-y-2 rounded-md bg-white p-4">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold text-previsionblue">
          INFORMATION DES RESPONSABLES LÉGAUX
        </h3>
        <Button
          variant="outline"
          className="w-auto"
          onClick={() => {
            triggerEdit('referent');
          }}
        >
          <SquarePen size={24} />
        </Button>
      </div>
      <Accordion type="multiple" defaultValue={['firstReferent']}>
        <AccordionItem value="firstReferent">
          <AccordionTrigger className="mx-2 text-lg font-extrabold text-previsionblue hover:no-underline lg:mx-auto">
            Responsable légal 1
          </AccordionTrigger>
          <AccordionContent>
            <div>
              <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto">
                Coordonnées
              </h4>
              <div className="flex flex-wrap">
                <DataCell
                  title="Civilité"
                  value={referent.civility === 'M' ? 'Monsieur' : 'Madame'}
                />
                <DataCell title="Prénom" value={referent.firstname} />
                <DataCell title="Nom" value={referent.lastname} />
                <DataCell
                  title="Date de naissance"
                  //@ts-expect-error -- Firebase envoi un type de Date particulier
                  value={formatDate(referent.birthdate.toDate(), 'dd/MM/yyyy')}
                />
                <DataCell title="Lieu de naissance" value={referent.placeOfBirth} />
              </div>
              <div>
                <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto">
                  Contact
                </h4>
                <div className="flex flex-wrap">
                  <DataCell title="Email" value={referent.contact.email} />
                  <DataCell
                    title="Téléphone mobile"
                    value={referent.contact.mobilePhone}
                  />
                  {referent.contact.landlinePhone && (
                    <DataCell
                      title="Téléphone fixe"
                      value={referent.contact.landlinePhone}
                    />
                  )}
                </div>
              </div>
            </div>
            {'address' in referent && referent.address && (
              <div>
                <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto">
                  Adresse
                </h4>
                <div className="flex flex-wrap">
                  <DataCell title="Adresse" value={referent.address.street} />
                  <DataCell title="Code postal" value={referent.address.postalCode} />
                  <DataCell title="Ville" value={referent.address.city} />
                  <DataCell title="Pays" value={referent.address.country} />
                </div>
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="secondReferent">
          <AccordionTrigger className="mx-2 text-lg font-extrabold text-previsionblue hover:no-underline lg:mx-auto">
            Responsable légal 2
          </AccordionTrigger>
          {!secondReferent && (
            <AccordionContent>
              <div className="flex justify-center">
                <p className="text-lg text-gray-500">Aucune informations disponible</p>
              </div>
            </AccordionContent>
          )}
          {secondReferent && (
            <AccordionContent>
              <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto">
                Coordonnées
              </h4>
              <div className="flex flex-wrap">
                <DataCell
                  title="Civilité"
                  value={secondReferent.civility === 'M' ? 'Monsieur' : 'Madame'}
                />
                <DataCell title="Prénom" value={secondReferent.firstname} />
                <DataCell title="Nom" value={secondReferent.lastname} />
              </div>
              <div>
                <h4 className="mx-2 text-lg font-bold text-previsionblue lg:mx-auto">
                  Contact
                </h4>
                <div className="flex flex-wrap">
                  <DataCell
                    title="Téléphone mobile"
                    value={secondReferent.contact.mobilePhone}
                  />
                  <DataCell title="Email" value={secondReferent.contact.email} />
                </div>
              </div>
            </AccordionContent>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  );
};
