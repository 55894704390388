import { zodResolver } from '@hookform/resolvers/zod';
import { useLoaderData, useNavigate } from '@tanstack/react-router';
import { LoaderCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Asterisk } from '@/components/asterisk';
import { DatePicker } from '@/components/date-picker';
import { PhoneInput } from '@/components/phone-input';
import { SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { studentSchema } from '@/pages/inscriptions/student/studentInformationSchema';
import { stepOneSubmit } from '@/pages/inscriptions/student/studentInformationSubmit';
import { SchoolRecord } from '@/types/types';

export const StudentInformations = () => {
  const navigate = useNavigate();

  const {
    pendingInscription,
  }: {
    pendingInscription: SchoolRecord | null;
    // @ts-expect-error --  Les types de la propriété strict sont incompatibles.(Impossible d'assigner le type false au type true)
  } = useLoaderData({
    from: '/steps',
    strict: false,
  });

  const form = useForm<z.infer<typeof studentSchema>>({
    resolver: zodResolver(studentSchema),
    defaultValues: {
      civility: pendingInscription?.student?.civility ?? 'M',
      firstname: pendingInscription?.student?.firstname ?? '',
      lastname: pendingInscription?.student?.lastname ?? '',
      birthdate: pendingInscription?.student?.birthdate
        ? new Date(pendingInscription?.student?.birthdate)
        : new Date(),
      placeOfBirth: pendingInscription?.student?.placeOfBirth ?? '',
      nationality: pendingInscription?.student?.nationality ?? '',
      address: pendingInscription?.student?.address?.street ?? '',
      postalCode: pendingInscription?.student?.address?.postalCode ?? '',
      city: pendingInscription?.student?.address?.city ?? '',
      country: pendingInscription?.student?.address?.country ?? '',
      email: pendingInscription?.student?.contact?.email ?? '',
      mobilePhone: pendingInscription?.student?.contact?.mobilePhone ?? '',
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const response = await stepOneSubmit(data);

          if (response) {
            navigate({
              to: '/steps/two',
            });
          } else {
            console.error('Error adding document');
          }
        })}
        className="h-fit w-full bg-white px-7 py-4 text-black/60"
      >
        <SectionTitle title="Coordonnées de l'étudiant" />
        <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
          <FormField
            control={form.control}
            name="civility"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Civilité
                  <Asterisk />
                </FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue focus:ring-0">
                      <SelectValue placeholder="Selectionnez une civilité" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="M">Monsieur</SelectItem>
                    <SelectItem value="F">Madame</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="firstname"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Prénom
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastname"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Nom
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="birthdate"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Date de naissance
                  <Asterisk />
                </FormLabel>
                <DatePicker date={field.value} setDate={field.onChange} />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="placeOfBirth"
            render={({ field }) => (
              <FormItem className="focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Lieu de naissance
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="nationality"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Nationalité
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <SectionTitle title="Adresse" />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem className=" mb-2 focus-within:text-previsionblue">
              <FormLabel className="text-base">
                Adresse (numéro, rue)
                <Asterisk />
              </FormLabel>
              <Input {...field} variant="prevision" />
              <FormMessage />
            </FormItem>
          )}
        />
        <section className="mb-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6">
          <FormField
            control={form.control}
            name="postalCode"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Code postal
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Ville
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Pays
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <SectionTitle title="Contact" />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className=" mb-2 focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Adresse mail
                  <Asterisk />
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormDescription className="text-xs">
                  Attention, le courriel doit être valide afin de vous envoyer les
                  informations administratives.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="mobilePhone"
            render={({ field }) => (
              <FormItem className=" focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Numéro de téléphone portable
                  <Asterisk />
                </FormLabel>
                <PhoneInput
                  placeholder="Entrez votre numéro mobile"
                  international
                  defaultCountry="FR"
                  {...field}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button
          type="submit"
          variant="squaredBlue"
          disabled={form.formState.isSubmitting}
          className="ml-auto mr-0 mt-2 flex min-w-36"
        >
          {form.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Suivant'
          )}
        </Button>
      </form>
    </Form>
  );
};
