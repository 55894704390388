import { User } from '@firebase/auth';
import { deleteField, doc, setDoc } from 'firebase/firestore';
import ls from 'localstorage-slim';
import { z } from 'zod';

import { getAnonUser } from '@/auth/user';
import { db } from '@/lib/firebase';
import { referentSchema } from '@/pages/inscriptions/referent/referentInformationSchema';
import { Referent, SchoolRecord, SimplifiedPerson } from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const stepTwoSubmit = async (
  data: z.infer<typeof referentSchema>,
  saveToLocalStorage: boolean = true,
) => {
  const user: User = await getAnonUser();

  const previousData: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  const userUid =
    previousData && previousData.recordId ? previousData.recordId : user.uid;

  const referentInformations: Referent = {
    civility: data.civility,
    firstname: data.referentFirstName,
    lastname: data.referentLastName,
    birthdate: data.referentBirthDate,
    placeOfBirth: data.referentPlaceOfBirth,

    contact: {
      email: data.referentEmail,
      mobilePhone: data.referentMobilePhone,
    },
  };

  const recordData: Partial<SchoolRecord> = {
    formProgress:
      previousData && previousData?.formProgress >= 2 ? previousData.formProgress : 2,
    referent: referentInformations,
    updatedAt: new Date(),
  };

  if (
    data.hasSecondReferent &&
    data.secondReferentCivility &&
    data.secondReferentFirstName &&
    data.secondReferentLastName &&
    data.secondReferentEmail &&
    data.secondReferentMobilePhone
  ) {
    const secondReferent: SimplifiedPerson = {
      civility: data.secondReferentCivility,
      firstname: data.secondReferentFirstName,
      lastname: data.secondReferentLastName,
      contact: {
        email: data.secondReferentEmail,
        mobilePhone: data.secondReferentMobilePhone,
      },
    };

    recordData.secondReferent = secondReferent;
  } else if (!data.hasSecondReferent) {
    delete recordData.secondReferent;
  }

  try {
    const docRef = doc(db, 'inscriptions', userUid);

    await setDoc(docRef, recordData, {
      merge: true,
    });

    if (!data.hasSecondReferent) {
      await setDoc(docRef, { secondReferent: deleteField() }, { merge: true });
    }
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  if (saveToLocalStorage) {
    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(previousData || {}),
      ...recordData,
      ...(data.hasSecondReferent ? {} : { secondReferent: undefined }),
    });
  }
  return true;
};
