import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

const isNumber = (value: string) => !isNaN(Number(value));

export const studentSchema = z
  .object({
    civility: z.enum(['M', 'F']),
    firstname: z.string().min(1, { message: 'Prénom requis' }),
    lastname: z.string().min(1, {
      message: 'Nom requis',
    }),
    birthdate: z.date({
      required_error: 'Date de naissance requise',
      invalid_type_error: 'Date de naissance invalide',
    }),
    placeOfBirth: z.string().min(1, {
      message: 'Lieu de naissance requis',
    }),
    nationality: z.string().min(1, {
      message: 'Nationalité requise',
    }),
    address: z.string().min(1, {
      message: 'Adresse requise',
    }),
    postalCode: z
      .string()
      .min(1, {
        message: 'Code postal trop court',
      })
      .refine(isNumber, {
        message: 'Code postal invalide',
      }),
    city: z.string().min(1, {
      message: 'Ville requise',
    }),
    country: z.string().min(1, {
      message: 'Pays requis',
    }),
    email: z.string().email({
      message: 'Adresse mail invalide',
    }),
    mobilePhone: z
      .string()
      .refine(isValidPhoneNumber, { message: 'Numéro de téléphone invalide' }),
  })
  .required();
