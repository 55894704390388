import { User } from '@firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import ls from 'localstorage-slim';
import { z } from 'zod';

import { getAnonUser, isAdminUser } from '@/auth/user';
import { getValidDate } from '@/data/utils';
import { db } from '@/lib/firebase';
import { studentSchema } from '@/pages/inscriptions/student/studentInformationSchema';
import { SchoolRecord, Student } from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';
import { uidGenerate } from '@/utils/generator';

export const stepOneSubmit = async (
  data: z.infer<typeof studentSchema>,
  saveToLocalStorage: boolean = true,
) => {
  const user: User = await getAnonUser();

  const userIDCheck = (await isAdminUser(user)) ? uidGenerate() : user.uid;

  const previousLocalstorage: SchoolRecord | null =
    ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  const userUid =
    previousLocalstorage && previousLocalstorage.recordId
      ? previousLocalstorage.recordId
      : userIDCheck;

  const studentInformations: Student = {
    civility: data.civility,
    firstname: data.firstname,
    lastname: data.lastname,
    birthdate: data.birthdate,
    placeOfBirth: data.placeOfBirth,
    nationality: data.nationality,
    address: {
      street: data.address,
      postalCode: data.postalCode,
      city: data.city,
      country: data.country,
    },
    contact: {
      email: data.email,
      mobilePhone: data.mobilePhone,
    },
  };

  const recordData: SchoolRecord = {
    recordId: userUid,
    formProgress:
      previousLocalstorage && previousLocalstorage.formProgress
        ? previousLocalstorage.formProgress
        : 1,
    recordStatus: 'pending',
    isRecordValidated: false,
    student: studentInformations,
    createdAt: getValidDate(previousLocalstorage?.createdAt),
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', userUid);

    await setDoc(docRef, recordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }
  if (saveToLocalStorage) {
    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(previousLocalstorage || {}),
      ...recordData,
    });
  }
  return true;
};
