import { User } from '@firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import ls from 'localstorage-slim';
import { z } from 'zod';

import { getAnonUser } from '@/auth/user';
import { db } from '@/lib/firebase';
import { SchoolRecord, Scolarity } from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

import { scolaritySchema } from './scolaritySchema';

export const stepThreeSubmit = async (data: z.infer<typeof scolaritySchema>) => {
  const user: User = await getAnonUser();

  const previousData: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  const userUid =
    previousData && previousData.recordId ? previousData.recordId : user.uid;

  const elevenGradesSpecialities: Scolarity['elevenGradesSpecialities'] = {
    one: data.elevenGradesSpecialitiesOne || '',
    two: data.elevenGradesSpecialitiesTwo || '',
    three: data.elevenGradesSpecialitiesThree || null,
    averageResults: {
      one: data.elevenGradesSpecialitiesOneAverage || '',
      two: data.elevenGradesSpecialitiesTwoAverage || '',
      three: data.elevenGradesSpecialitiesThreeAverage || null,
    },
    commentaries: {
      one: data.elevenGradesSpecialitiesOneCommentary || null,
      two: data.elevenGradesSpecialitiesTwoCommentary || null,
      three: data.elevenGradesSpecialitiesThreeCommentary || null,
    },
  };

  const twelveGradesSpecialities: Scolarity['twelveGradesSpecialities'] = {
    one: data.twelveGradesSpecialitiesOne || '',
    two: data.twelveGradesSpecialitiesTwo || '',
    variant: data.twelveGradesSpecialitiesVariant || null,
    dropped: data.twelveGradesDroppedSpecialities || null,
    averageResults: {
      one: data.twelveGradesSpecialitiesOneAverage || '',
      two: data.twelveGradesSpecialitiesTwoAverage || '',
      variant: data.twelveGradesSpecialitiesVariantAverage || null,
      dropped: data.twelveGradesDroppedSpecialitiesAverage || null,
    },
    commentaries: {
      one: data.twelveGradesSpecialitiesOneCommentary || null,
      two: data.twelveGradesSpecialitiesTwoCommentary || null,
      variant: data.twelveGradesSpecialitiesVariantCommentary || null,
      dropped: data.twelveGradesDroppedSpecialitiesCommentary || null,
    },
  };

  const scolarityInformations: Scolarity = {
    lastPlaceAttended: data.lastPlaceAttended,
    lastPlaceAttendedAddress: data.lastPlaceAttendedAddress,
    lastPlaceAttendedPostalCode: data.lastPlaceAttendedPostalCode,
    lastPlaceAttendedCity: data.lastPlaceAttendedCity,
    initialGradeLevel: data.initalGradeLevel,
    elevenGradesSpecialities:
      data.initalGradeLevel === 'eleventhGrade' ? elevenGradesSpecialities : null,
    twelveGradesSpecialities:
      data.initalGradeLevel === 'twelfthGrade' ? twelveGradesSpecialities : null,
    learnedLanguage: data.learnedLanguage,
    learnedlanguageAverageResults: data.learnedlanguageAverageResults,
    learnedlanguageCommentaries: data.learnedlanguageCommentaries || null,
  };

  if (data.studentMotivation) {
    scolarityInformations.motivation = data.studentMotivation;
  }

  const recordData: Partial<SchoolRecord> = {
    formProgress:
      previousData && previousData?.formProgress >= 3 ? previousData.formProgress : 3,
    scolarity: scolarityInformations,
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', userUid);

    await setDoc(docRef, recordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  try {
    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(previousData || {}),
      ...recordData,
    });
  } catch (error) {
    console.error('Error adding local: ', error);
    return false;
  }

  return true;
};
