import { Outlet, useLoaderData } from '@tanstack/react-router';
import { useEffect } from 'react';

import logo from '@/assets/images/prevision-logo.png';
import { PaymentExplanation } from '@/components/payment-explanation';
import { StepCell } from '@/components/step-cell';
import { useInscriptionsStore } from '@/store/store';
import { SchoolRecord } from '@/types/types';

export const StepsLayout = () => {
  const formuleInformationsPanel = useInscriptionsStore((state) => state.chosenFormule);
  const setDisplayInformations = useInscriptionsStore((state) => state.addFormule);

  const { pendingInscription } = useLoaderData({
    from: '/steps',
  });

  useEffect(() => {
    if (pendingInscription && pendingInscription.formule) {
      setDisplayInformations(pendingInscription.formule);
    }
  }, [pendingInscription, setDisplayInformations]);

  return (
    <>
      <header className="h-16 w-full bg-white px-4">
        <img src={logo} className="h-full py-1.5" title="logo" />
      </header>
      <main className="flex min-h-screen justify-evenly bg-previsiongrey px-4 py-7 font-montserrat md:px-7">
        <nav className="mr-2 hidden w-1/4 flex-col space-y-6 py-4 font-bold text-previsionblue lg:flex">
          <StepCell
            position={1}
            title={"Informations de l'étudiant"}
            path="steps/one"
            disabled={!canAccess(pendingInscription, 1)}
          />
          <StepCell
            position={2}
            title={'Informations du responsable légal'}
            path="steps/two"
            disabled={!canAccess(pendingInscription, 2)}
          />
          <StepCell
            position={3}
            title={'Scolarité et motivations'}
            path="steps/three"
            disabled={!canAccess(pendingInscription, 3)}
          />
          <StepCell
            position={4}
            title={'Choix de ma formule'}
            path="steps/four"
            disabled={!canAccess(pendingInscription, 4)}
          />
          <StepCell
            position={5}
            title={'Mes documents'}
            path="steps/five"
            disabled={!canAccess(pendingInscription, 5)}
          />
          <StepCell
            position={6}
            title={'Conditions générales'}
            path="steps/six"
            disabled={!canAccess(pendingInscription, 6)}
          />
          <StepCell
            position={7}
            title={'Acompte'}
            path="steps/seven"
            disabled={!canAccess(pendingInscription, 7)}
          />
          <StepCell
            position={8}
            title={'Validation'}
            path="steps/eight"
            disabled={!canAccess(pendingInscription, 8)}
          />
        </nav>
        <Outlet />
        <PaymentExplanation data={formuleInformationsPanel} />
      </main>
    </>
  );
};

const canAccess = (data: SchoolRecord | null, step: number) => {
  const userStep = data?.formProgress || 0;

  if (userStep === 8 && step !== 8) return false;

  if (step === 2 && userStep === 0) return false;

  if (step === 8 && userStep <= 7) return false;

  if (userStep + 1 >= step) return true;

  return false;
};
