import { z } from 'zod';

const isNumber = (value: string | undefined) => !isNaN(Number(value ? value : ''));

const checkIfNoteIsValid = (val: string) => {
  //Check if the value is a number (decimal or integer) between 0.00 and 20.00
  const regex = new RegExp(/^(?:[0-9]|1[0-9]|20)(?:\.\d{1,2})?$/);
  return regex.test(val);
};

const errorMessage = {
  required_error: 'Ce champ est requis',
  invalid_note: 'La note doit être comprise entre 0.00 et 20.00',
  invalid_length: 'Veuillez remplir ce champ',
  invalid_motivation: 'Merci de faire un petit résumé de votre motivation',
  invalidLength: 'Merci de renseigner plus de 10 caractères',
  place_attended_required: 'Dernier établissement fréquenté requis',
};

const noteSchema = z
  .string()
  .refine(checkIfNoteIsValid, {
    message: errorMessage.invalid_note,
  })
  .optional();

export const scolaritySchema = z
  .object({
    lastPlaceAttended: z.string({
      required_error: errorMessage.place_attended_required,
    }),
    lastPlaceAttendedAddress: z.string(),
    lastPlaceAttendedPostalCode: z.string(),
    lastPlaceAttendedCity: z.string(),
    otherLastPlaceAttended: z.string().optional(),
    otherLastPlaceAttendedAddress: z.string().optional(),
    otherLastPlaceAttendedPostalCode: z.string().optional().refine(isNumber, {
      message: 'Code postal invalide',
    }),
    otherLastPlaceAttendedCity: z.string().optional(),
    initalGradeLevel: z.enum(['twelfthGrade', 'eleventhGrade']),
    twelveGradesSpecialitiesOne: z
      .string()
      .min(2, {
        message: errorMessage.required_error,
      })
      .optional(),
    twelveGradesSpecialitiesOneAverage: noteSchema,
    twelveGradesSpecialitiesOneCommentary: z.string().optional(),
    twelveGradesSpecialitiesTwo: z
      .string()
      .min(2, {
        message: errorMessage.required_error,
      })
      .optional(),
    twelveGradesSpecialitiesTwoAverage: noteSchema,
    twelveGradesSpecialitiesTwoCommentary: z.string().optional(),
    twelveGradesSpecialitiesVariant: z.string().optional(),
    twelveGradesSpecialitiesVariantAverage: noteSchema
      .or(z.literal(''))
      .transform((val) => {
        if (val === '') {
          return undefined;
        }
        return val;
      }),
    twelveGradesSpecialitiesVariantCommentary: z.string().optional(),
    twelveGradesDroppedSpecialities: z
      .string()
      .min(2, {
        message: errorMessage.required_error,
      })
      .optional(),
    twelveGradesDroppedSpecialitiesAverage: noteSchema,
    twelveGradesDroppedSpecialitiesCommentary: z.string().optional(),
    elevenGradesSpecialitiesOne: z
      .string()
      .min(2, {
        message: errorMessage.invalid_length,
      })
      .optional(),
    elevenGradesSpecialitiesOneAverage: noteSchema,
    elevenGradesSpecialitiesOneCommentary: z.string().optional(),
    elevenGradesSpecialitiesTwo: z
      .string()
      .min(2, {
        message: errorMessage.invalid_length,
      })
      .optional(),
    elevenGradesSpecialitiesTwoAverage: noteSchema,
    elevenGradesSpecialitiesTwoCommentary: z.string().optional(),
    elevenGradesSpecialitiesThree: z.string().optional(),
    elevenGradesSpecialitiesThreeAverage: noteSchema,
    elevenGradesSpecialitiesThreeCommentary: z.string().optional(),
    learnedLanguage: z.enum(['french', 'english']),
    learnedlanguageAverageResults: z.string().refine(checkIfNoteIsValid, {
      message: errorMessage.invalid_note,
    }),
    learnedlanguageCommentaries: z.string().optional(),
    studentMotivation: z
      .string({
        required_error: errorMessage.invalid_motivation,
      })
      .min(10, {
        message: errorMessage.invalidLength,
      })
      .optional()
      .or(z.literal(''))
      .transform((val) => {
        if (val === '') {
          return undefined;
        }
        return val;
      }),
  })
  .superRefine((val, ctx) => {
    let isCorrect = true;
    if (val.initalGradeLevel === 'twelfthGrade') {
      //The following fields are required (with the average results)
      // - twelveGradesSpecialitiesOne
      // - twelveGradesSpecialitiesTwo
      // - twelveGradesDroppedSpecialities
      // - learnedLanguage

      const requiredFields: (keyof typeof val)[] = [
        'twelveGradesSpecialitiesOne',
        'twelveGradesSpecialitiesOneAverage',
        'twelveGradesSpecialitiesTwo',
        'twelveGradesSpecialitiesTwoAverage',
        'twelveGradesDroppedSpecialities',
        'twelveGradesDroppedSpecialitiesAverage',
        'learnedLanguage',
        'learnedlanguageAverageResults',
      ];

      const missingFields = requiredFields.filter((field) => val[field] === undefined);

      if (missingFields.length > 0) {
        for (const field of missingFields) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errorMessage.required_error,
            path: [field],
          });

          isCorrect = false;
        }
      }
    }
    if (val.initalGradeLevel === 'eleventhGrade') {
      //The following fields are required (with the average results)
      // - elevenGradesSpecialitiesOne
      // - elevenGradesSpecialitiesTwo
      // - elevenGradesSpecialitiesThree
      // - learnedLanguage

      const requiredFields: (keyof typeof val)[] = [
        'elevenGradesSpecialitiesOne',
        'elevenGradesSpecialitiesOneAverage',
        'elevenGradesSpecialitiesTwo',
        'elevenGradesSpecialitiesTwoAverage',
        'elevenGradesSpecialitiesThree',
        'elevenGradesSpecialitiesThreeAverage',
        'learnedLanguage',
        'learnedlanguageAverageResults',
      ];

      const missingFields = requiredFields.filter((field) => val[field] === undefined);

      if (missingFields.length > 0) {
        for (const field of missingFields) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errorMessage.required_error,
            path: [field],
          });

          isCorrect = false;
        }
      }
    }

    if (val.elevenGradesSpecialitiesThree && !val.elevenGradesSpecialitiesThreeAverage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.required_error,
        path: ['elevenGradesSpecialitiesThreeAverage'],
      });

      isCorrect = false;
    }
    if (val.elevenGradesSpecialitiesThreeAverage && !val.elevenGradesSpecialitiesThree) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.required_error,
        path: ['elevenGradesSpecialitiesThree'],
      });

      isCorrect = false;
    }

    if (
      val.twelveGradesSpecialitiesVariant &&
      !val.twelveGradesSpecialitiesVariantAverage
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.required_error,
        path: ['twelveGradesSpecialitiesVariantAverage'],
      });

      isCorrect = false;
    }
    if (
      val.twelveGradesSpecialitiesVariantAverage &&
      !val.twelveGradesSpecialitiesVariant
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.required_error,
        path: ['twelveGradesSpecialitiesVariant'],
      });

      isCorrect = false;
    }

    if (val.lastPlaceAttended === 'autre' && !val.otherLastPlaceAttended) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.required_error,
        path: ['otherLastPlaceAttended'],
      });

      isCorrect = false;
    }

    if (val.lastPlaceAttended === 'autre' && !val.otherLastPlaceAttendedCity) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.required_error,
        path: ['otherLastPlaceAttendedCity'],
      });

      isCorrect = false;
    }

    if (val.lastPlaceAttended === 'autre' && !val.otherLastPlaceAttendedPostalCode) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.required_error,
        path: ['otherLastPlaceAttendedPostalCode'],
      });

      isCorrect = false;
    }

    if (val.otherLastPlaceAttended && val.otherLastPlaceAttended?.length < 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage.invalid_length,
        path: ['otherLastPlaceAttended'],
      });

      isCorrect = false;
    }

    return isCorrect;
  });
