import { zodResolver } from '@hookform/resolvers/zod';
import { useParams, useRouter } from '@tanstack/react-router';
import { deleteField, doc, DocumentData, getDoc, setDoc } from 'firebase/firestore';
import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { DialogContent, DialogTitle } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
/* import { previsionDB, PrevisionSchema } from '@/data/typesaurus'; */
import { db } from '@/lib/firebase';

export const PastScolarityEdit = (/* {
  toggleModal,
}: {
  toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
} */) => {
  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const [selectedEdit, setSelectedEdit] = useState<
    'lastPlaceAttended' | '1er' | 'Ter' | 'Motivation' | 'old' | 'learnedLanguage'
  >('lastPlaceAttended');
  const [record, setRecord] = useState<DocumentData | null | undefined>(null);

  const fetchRecord = async () => {
    const docRef = doc(db, 'inscriptions', inscriptionId);
    const docData = await getDoc(docRef);

    setRecord(docData.data());
  };

  useEffect(() => {
    fetchRecord();
  }, []);

  if (!record) {
    return (
      <DialogContent>
        <LoaderCircle className="m-auto animate-spin text-previsionblue" size={32} />
      </DialogContent>
    );
  }

  return (
    <DialogContent>
      <DialogTitle className="text-xl font-bold text-previsionblue">
        Modifier les informations de Spécialités
      </DialogTitle>

      <Select
        value={selectedEdit}
        onValueChange={(value) =>
          setSelectedEdit(value as '1er' | 'Ter' | 'Motivation' | 'learnedLanguage')
        }
      >
        <SelectTrigger>
          <SelectValue placeholder="Modification de ..." />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="lastPlaceAttended">
              Modification du dernier établissement fréquenté
            </SelectItem>
            <SelectItem value="Ter">Modification des options de Terminale</SelectItem>
            <SelectItem value="1er">Modification des options de Première</SelectItem>
            <SelectItem value="Motivation">
              Modification du texte de motivation
            </SelectItem>
            <SelectItem value="learnedLanguage">
              Modification des infos de langue vivante
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Separator className="my-1" />

      {selectedEdit === 'lastPlaceAttended' ? (
        <LastPlaceAttendedForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}

      {selectedEdit === '1er' ? (
        <ElevenGradesForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
      {selectedEdit === 'Ter' ? (
        <TwelveGradesForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
      {selectedEdit === 'Motivation' ? (
        <MotivationForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
      {selectedEdit === 'old' ? (
        <OldElevenGradesForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
      {selectedEdit === 'learnedLanguage' ? (
        <LearnedLanguageForm record={record} /* toggleModal={toggleModal} */ />
      ) : null}
    </DialogContent>
  );
};

/******** Form for "Learned Language" section ********/
const LearnedLanguageFormSchema = z.object({
  learnedLanguage: z.enum(['Anglais', 'Français']),
  learnedlanguageAverageResults: z.string().optional(),
  learnedlanguageCommentaries: z.string().optional(),
});
const LearnedLanguageForm = ({ record }: { record: DocumentData }) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const langForm = useForm<z.infer<typeof LearnedLanguageFormSchema>>({
    resolver: zodResolver(LearnedLanguageFormSchema),
    defaultValues: {
      learnedLanguage:
        (record.scolarity?.learnedLanguage &&
          (record.scolarity.learnedLanguage === 'english' ? 'Anglais' : 'Français')) ??
        undefined,
      learnedlanguageAverageResults:
        record.scolarity?.learnedlanguageAverageResults ?? '',
      learnedlanguageCommentaries: record.scolarity?.learnedlanguageCommentaries ?? '',
    },
  });

  if (!inscriptionId) {
    return null;
  }

  return (
    <Form {...langForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={langForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  learnedLanguage:
                    data.learnedLanguage === 'Anglais' ? 'english' : 'french',
                  learnedlanguageAverageResults:
                    data.learnedlanguageAverageResults ?? null,
                  learnedlanguageCommentaries: data.learnedlanguageCommentaries ?? null,
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification des informations de langue vivante - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col space-y-2 overflow-y-auto py-2 text-black/60">
          <FormField
            control={langForm.control}
            name="learnedLanguage"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Langue vivante</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Choisir une langue" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="Anglais">Anglais</SelectItem>
                    <SelectItem value="Français">Français</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={langForm.control}
            name="learnedlanguageAverageResults"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Moyenne</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={langForm.control}
            name="learnedlanguageCommentaries"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Commentaires</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            variant="dashboardBlue"
            disabled={langForm.formState.isSubmitting}
            type="submit"
            className="max-w-1/2 min-w-1/3 my-4 self-end"
          >
            {langForm.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Sauvegarder - Langue vivante'
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};

/******** Form for "Last Place Attended" section ********/
const LastPlaceAttendedFormSchema = z.object({
  lastPlaceAttended: z.string().optional(),
  lastPlaceAttendedPostalCode: z.string().optional(),
  lastPlaceAttendedCity: z.string().optional(),
  lastPlaceAttendedAddress: z.string().optional(),
});
const LastPlaceAttendedForm = ({ record }: { record: DocumentData }) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const getLastPlaceAttended =
    record.student?.lastPlaceAttended ?? record.scolarity?.lastPlaceAttended;

  const lastPlaceForm = useForm<z.infer<typeof LastPlaceAttendedFormSchema>>({
    resolver: zodResolver(LastPlaceAttendedFormSchema),
    defaultValues: {
      lastPlaceAttended: getLastPlaceAttended ?? undefined,
      lastPlaceAttendedAddress: record.scolarity?.lastPlaceAttendedAddress ?? '',
      lastPlaceAttendedCity: record.scolarity?.lastPlaceAttendedCity ?? '',
      lastPlaceAttendedPostalCode: record.scolarity?.lastPlaceAttendedPostalCode ?? '',
    },
  });

  if (!inscriptionId) {
    return null;
  }

  return (
    <Form {...lastPlaceForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={lastPlaceForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  lastPlaceAttended: data.lastPlaceAttended ?? null,
                  lastPlaceAttendedAddress: data.lastPlaceAttendedAddress ?? null,
                  lastPlaceAttendedCity: data.lastPlaceAttendedCity ?? null,
                  lastPlaceAttendedPostalCode: data.lastPlaceAttendedPostalCode ?? null,
                },
              },
              { merge: true },
            );

            //If the lastPlaceAttended is also in the student object, we remove it
            if (record.student?.lastPlaceAttended) {
              await setDoc(
                docRef,
                {
                  student: {
                    lastPlaceAttended: deleteField(),
                  },
                },
                { merge: true },
              );
            }

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification du dernier établissement fréquenté - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col space-y-2 overflow-y-auto py-2 text-black/60">
          <FormField
            control={lastPlaceForm.control}
            name="lastPlaceAttended"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Dernier établissement fréquenté
                </FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={lastPlaceForm.control}
            name="lastPlaceAttendedAddress"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Adresse</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={lastPlaceForm.control}
            name="lastPlaceAttendedCity"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Ville</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={lastPlaceForm.control}
            name="lastPlaceAttendedPostalCode"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Code postal</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button
          variant="dashboardBlue"
          disabled={lastPlaceForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {lastPlaceForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Dernier établissement'
          )}
        </Button>
      </form>
    </Form>
  );
};

/******** Form for "Terminale" section ********/
const TwelveGradesFormSchema = z.object({
  twelveGradesSpecialitiesOne: z.string().optional(),
  TwelveGradesSpecialitiesOneAverage: z.string().optional(),
  TwelveGradesSpecialitiesOneCommentary: z.string().optional(),
  twelveGradesSpecialitiesTwo: z.string().optional(),
  TwelveGradesSpecialitiesTwoAverage: z.string().optional(),
  TwelveGradesSpecialitiesTwoCommentary: z.string().optional(),
  twelveGradesSpecialitiesVariant: z.string().optional(),
  TwelveGradesSpecialitiesVariantAverage: z.string().optional(),
  TwelveGradesSpecialitiesVariantCommentary: z.string().optional(),
  twelveGradesDroppedSpecialities: z.string().optional(),
  TwelveGradesDroppedSpecialitiesAverage: z.string().optional(),
  TwelveGradesDroppedSpecialitiesCommentary: z.string().optional(),
});
const TwelveGradesForm = ({
  record,
  //toggleModal,
}: {
  record: DocumentData;
  //toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const terForm = useForm<z.infer<typeof TwelveGradesFormSchema>>({
    resolver: zodResolver(TwelveGradesFormSchema),
    defaultValues: {
      twelveGradesSpecialitiesOne:
        record.scolarity?.twelveGradesSpecialities?.one ?? undefined,
      TwelveGradesSpecialitiesOneAverage:
        record.scolarity?.twelveGradesSpecialities?.averageResults?.one ?? undefined,
      TwelveGradesSpecialitiesOneCommentary:
        record.scolarity?.twelveGradesSpecialities?.commentaries?.one ?? undefined,
      twelveGradesSpecialitiesTwo:
        record.scolarity?.twelveGradesSpecialities?.two ?? undefined,
      TwelveGradesSpecialitiesTwoAverage:
        record.scolarity?.twelveGradesSpecialities?.averageResults?.two ?? undefined,
      twelveGradesSpecialitiesVariant:
        record.scolarity?.twelveGradesSpecialities?.variant ?? undefined,
      TwelveGradesSpecialitiesVariantAverage:
        record.scolarity?.twelveGradesSpecialities?.averageResults?.variant ?? undefined,
      TwelveGradesSpecialitiesVariantCommentary:
        record.scolarity?.twelveGradesSpecialities?.commentaries?.variant ?? undefined,
      twelveGradesDroppedSpecialities:
        record.scolarity?.twelveGradesSpecialities?.dropped ?? undefined,
      TwelveGradesDroppedSpecialitiesAverage:
        record.scolarity?.twelveGradesSpecialities?.averageResults?.dropped ?? undefined,
      TwelveGradesDroppedSpecialitiesCommentary:
        record.scolarity?.twelveGradesSpecialities?.commentaries?.dropped ?? undefined,
    },
  });

  if (!inscriptionId) {
    return null;
  }

  return (
    <Form {...terForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={terForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  twelveGradesSpecialities: {
                    one: data.twelveGradesSpecialitiesOne ?? null,
                    two: data.twelveGradesSpecialitiesTwo ?? null,
                    variant: data.twelveGradesSpecialitiesVariant ?? null,
                    dropped: data.twelveGradesDroppedSpecialities ?? null,
                    averageResults: {
                      one: data.TwelveGradesSpecialitiesOneAverage ?? null,
                      two: data.TwelveGradesSpecialitiesTwoAverage ?? null,
                      variant: data.TwelveGradesSpecialitiesVariantAverage ?? null,
                      dropped: data.TwelveGradesDroppedSpecialitiesAverage ?? null,
                    },
                    commentaries: {
                      one: data.TwelveGradesSpecialitiesOneCommentary ?? null,
                      two: data.TwelveGradesSpecialitiesTwoCommentary ?? null,
                      variant: data.TwelveGradesSpecialitiesVariantCommentary ?? null,
                      dropped: data.TwelveGradesDroppedSpecialitiesCommentary ?? null,
                    },
                  },
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification des spécialités Terminale - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col overflow-y-auto py-2 text-black/60">
          <div className="my-4">
            <FormField
              control={terForm.control}
              name="twelveGradesSpecialitiesOne"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Terminale - Spécialité 1</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={terForm.control}
              name="TwelveGradesSpecialitiesOneAverage"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Moyenne</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={terForm.control}
              name="TwelveGradesSpecialitiesOneCommentary"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Commentaires</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="my-4">
            <FormField
              control={terForm.control}
              name="twelveGradesSpecialitiesTwo"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Terminale - Spécialité 2</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={terForm.control}
              name="TwelveGradesSpecialitiesTwoAverage"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Moyenne</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={terForm.control}
              name="TwelveGradesSpecialitiesTwoCommentary"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Commentaires</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="my-4">
            <FormField
              control={terForm.control}
              name="twelveGradesSpecialitiesVariant"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Option</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={terForm.control}
              name="TwelveGradesSpecialitiesVariantAverage"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Moyenne</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={terForm.control}
              name="TwelveGradesSpecialitiesVariantCommentary"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Commentaires</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="my-4">
            <FormField
              control={terForm.control}
              name="twelveGradesDroppedSpecialities"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">
                    Spécialité abandonnée en Première
                  </FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={terForm.control}
              name="TwelveGradesDroppedSpecialitiesAverage"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Moyenne</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={terForm.control}
              name="TwelveGradesDroppedSpecialitiesCommentary"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Commentaires</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <Button
          variant="dashboardBlue"
          disabled={terForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {terForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Terminale'
          )}
        </Button>
      </form>
    </Form>
  );
};

/******** Form for "Première" section ********/
const ElevenGradesFormSchema = z.object({
  elevenGradesSpecialitiesOne: z.string().optional(),
  elevenGradesSpecialitiesOneAverage: z.string().optional(),
  elevenGradesSpecialitiesOneCommentary: z.string().optional(),
  elevenGradesSpecialitiesTwo: z.string().optional(),
  elevenGradesSpecialitiesTwoAverage: z.string().optional(),
  elevenGradesSpecialitiesTwoCommentary: z.string().optional(),
  elevenGradesSpecialitiesThree: z.string().optional(),
  elevenGradesSpecialitiesThreeAverage: z.string().optional(),
  elevenGradesSpecialitiesThreeCommentary: z.string().optional(),
});
const ElevenGradesForm = ({ record }: { record: DocumentData }) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const preForm = useForm<z.infer<typeof ElevenGradesFormSchema>>({
    resolver: zodResolver(ElevenGradesFormSchema),
    defaultValues: {
      elevenGradesSpecialitiesOne:
        record.scolarity?.elevenGradesSpecialities?.one ?? undefined,
      elevenGradesSpecialitiesOneAverage:
        record.scolarity?.elevenGradesSpecialities?.averageResults?.one ?? undefined,
      elevenGradesSpecialitiesOneCommentary:
        record.scolarity?.elevenGradesSpecialities?.commentaries?.one ?? undefined,
      elevenGradesSpecialitiesTwo:
        record.scolarity?.elevenGradesSpecialities?.two ?? undefined,
      elevenGradesSpecialitiesTwoAverage:
        record.scolarity?.elevenGradesSpecialities?.averageResults?.two ?? undefined,
      elevenGradesSpecialitiesTwoCommentary:
        record.scolarity?.elevenGradesSpecialities?.commentaries?.two ?? undefined,
      elevenGradesSpecialitiesThree:
        record.scolarity?.elevenGradesSpecialities?.three ?? undefined,
      elevenGradesSpecialitiesThreeAverage:
        record.scolarity?.elevenGradesSpecialities?.averageResults?.three ?? undefined,
      elevenGradesSpecialitiesThreeCommentary:
        record.scolarity?.elevenGradesSpecialities?.commentaries?.three ?? undefined,
    },
  });

  return (
    <Form {...preForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={preForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  elevenGradesSpecialities: {
                    one: data.elevenGradesSpecialitiesOne ?? null,
                    two: data.elevenGradesSpecialitiesTwo ?? null,
                    three: data.elevenGradesSpecialitiesThree ?? null,
                    averageResults: {
                      one: data.elevenGradesSpecialitiesOneAverage ?? null,
                      two: data.elevenGradesSpecialitiesTwoAverage ?? null,
                      three: data.elevenGradesSpecialitiesThreeAverage ?? null,
                    },
                    commentaries: {
                      one: data.elevenGradesSpecialitiesOneCommentary ?? null,
                      two: data.elevenGradesSpecialitiesTwoCommentary ?? null,
                      three: data.elevenGradesSpecialitiesThreeCommentary ?? null,
                    },
                  },
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification des spécialités Première - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col overflow-y-auto py-2 text-black/60">
          <div className="my-4">
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesOne"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Première - Spécialité 1</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesOneAverage"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Moyenne</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesOneCommentary"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Commentaires</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="my-4">
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesTwo"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Première - Spécialité 2</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesTwoAverage"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Moyenne</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesTwoCommentary"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Commentaires</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="my-4">
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesThree"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Première - Spécialité 3</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesThreeAverage"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Moyenne</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={preForm.control}
              name="elevenGradesSpecialitiesThreeCommentary"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <FormLabel className="text-base">Commentaires</FormLabel>
                  <Input {...field} variant="prevision" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <Button
          variant="dashboardBlue"
          disabled={preForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {preForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Première'
          )}
        </Button>
      </form>
    </Form>
  );
};

/******** Form for "Old Première" section ********/
const OldElevenGradesFormSchema = z.object({
  elevenGradesSpecialitiesOne: z.string().optional(),
  elevenGradesSpecialitiesTwo: z.string().optional(),
  elevenGradesSpecialitiesThree: z.string().optional(),
});
const OldElevenGradesForm = ({
  record,
  //toggleModal,
}: {
  record: DocumentData;
  //toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const preForm = useForm<z.infer<typeof OldElevenGradesFormSchema>>({
    resolver: zodResolver(OldElevenGradesFormSchema),
    defaultValues: {
      elevenGradesSpecialitiesOne: record.scolarity.diplomaSpecialities?.[0] ?? '',
      elevenGradesSpecialitiesTwo: record.scolarity.diplomaSpecialities?.[1] ?? '',
      elevenGradesSpecialitiesThree: record.scolarity.diplomaSpecialities?.[2] ?? '',
    },
  });

  return (
    <Form {...preForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={preForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  diplomaSpecialities: [
                    data.elevenGradesSpecialitiesOne ?? '',
                    data.elevenGradesSpecialitiesTwo ?? '',
                    ...(data.elevenGradesSpecialitiesThree
                      ? [data.elevenGradesSpecialitiesThree ?? '']
                      : []),
                  ],
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification des spécialités Première - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col space-y-2 overflow-y-auto py-2 text-black/60">
          <FormField
            control={preForm.control}
            name="elevenGradesSpecialitiesOne"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Première - Spécialité 1</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={preForm.control}
            name="elevenGradesSpecialitiesTwo"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Première - Spécialité 2</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={preForm.control}
            name="elevenGradesSpecialitiesThree"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Première - Spécialité 3</FormLabel>
                <Input {...field} variant="prevision" />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button
          variant="dashboardBlue"
          disabled={preForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {preForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Première'
          )}
        </Button>
      </form>
    </Form>
  );
};

/******** Form for "Motivation" section ********/
const MotivationFormSchema = z.object({
  studentMotivation: z.string().optional(),
});
const MotivationForm = ({
  record,
  //toggleModal,
}: {
  record: DocumentData;
  //toggleModal: (from: 'student' | 'referent' | 'formule' | 'scolarity' | 'files') => void;
}) => {
  const router = useRouter();

  const { inscriptionId } = useParams({
    from: '/admin/inscriptions/$inscriptionId',
  });

  const motivationForm = useForm<z.infer<typeof MotivationFormSchema>>({
    resolver: zodResolver(MotivationFormSchema),
    defaultValues: {
      studentMotivation: record.scolarity.motivation ?? undefined,
    },
  });

  if (!inscriptionId) {
    return null;
  }

  return (
    <Form {...motivationForm}>
      <form
        className="flex max-h-[70vh] flex-col overflow-auto"
        onSubmit={motivationForm.handleSubmit(async (data) => {
          try {
            const docRef = doc(db, 'inscriptions', inscriptionId);
            await setDoc(
              docRef,
              {
                scolarity: {
                  motivation: data.studentMotivation ?? null,
                },
              },
              { merge: true },
            );

            router.invalidate();

            //toggleModal('scolarity');
            toast('Modifications effectuées');
          } catch (error) {
            console.error(
              `Erreur lors de la modification du texte de motivation - ${error}`,
            );
          }
        })}
      >
        <div className="flex w-full flex-col space-y-2 overflow-y-auto py-2 text-black/60">
          <FormField
            control={motivationForm.control}
            name="studentMotivation"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormLabel className="text-base">Motivation</FormLabel>
                <Textarea
                  {...field}
                  className="rounded-none border border-black/60 focus:border-2 focus:border-previsionblue"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <Button
          variant="dashboardBlue"
          disabled={motivationForm.formState.isSubmitting}
          type="submit"
          className="max-w-1/2 min-w-1/3 my-4 self-end"
        >
          {motivationForm.formState.isSubmitting ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            'Sauvegarder - Motivation'
          )}
        </Button>
      </form>
    </Form>
  );
};
