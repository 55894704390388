import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useLoaderData, useNavigate } from '@tanstack/react-router';
import { useDebounce } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Asterisk } from '@/components/asterisk';
import { SectionSubtitle, SectionTitle } from '@/components/titles';
import { Button } from '@/components/ui/button';
import { Combobox } from '@/components/ui/combobox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { WithCreateCombobox } from '@/components/with-create-combobox';
import { SchoolRecord } from '@/types/types';

import { scolaritySchema } from './scolaritySchema';
import { stepThreeSubmit } from './scolaritySubmit';

interface FrenchHighSchoolAPIItem {
  code_uai: string;
  num_siret: number;
  code_academie: string;
  academie: string;
  dep: string;
  sigle_uai: string;
  nature_uai: string;
  patronyme: string;
  nom_etablissement: string;
  statut: string;
  contrat_etablissement: string | null;
  adresse_postale: string;
  code_postal: string;
  code_insee: string;
  libelle: string;
  telephone: string;
  fax: string;
  site_web: string;
  mail: string;
  ministere_tutelle: string;
  internat: string | null;
  annee_construction: string;
  date_maj: string;
  geo: {
    lon: number;
    lat: number;
  };
}

const SPECIALITIES = [
  'Mathématiques',
  'SES: Sciences économiques et sociales',
  'HGGSP: Histoire-Géographie, Géopolitique et Sciences Politiques',
  'LLCER Anglais: Langue, Littérature et Culture Étrangère et Régionale Anglais',
  'HLP: Humanités, Littérature et Philosophie',
  'Physique-Chimie',
  'SI: Sciences de l’Ingénieur',
  'NSI: Numérique et Sciences Informatiques',
  'SVT: Sciences de la Vie et de la Terre',
  'Mercatique (Marketing)',
  'Gestion et Finance',
  'Ressources Humaines et Communication',
  'Systemes d’Information de Gestion',
  'Innovation Technologique et Éco-Conception',
  "Systemes d'Information et Numérique",
  'Énergies et Environnement',
  'Architecture et Construction',
  'Sciences physiques et chimiques en laboratoire',
  'Biotechnologies',
  'Santé',
  'Social',
];

const TWELVE_GRADES_SPECIALITIES_VARIANT = [
  'Mathématiques expertes',
  'Mathématiques complémentaires',
];

const SPECIALITIES_COMBOBOX_PLACEHOLDER = 'Chercher votre spécialité';
const OPTION_COMBOBOX_PLACEHOLDER = 'Chercher votre option';

export const ScolarityInfomations = () => {
  const navigate = useNavigate();

  const {
    pendingInscription,
  }: {
    pendingInscription: SchoolRecord | null;
    // @ts-expect-error Les types de la propriété strict sont incompatibles.(Impossible d'assigner le type false au type true)
  } = useLoaderData({
    from: '/steps',
    strict: false,
  });

  const form = useForm<z.infer<typeof scolaritySchema>>({
    resolver: zodResolver(scolaritySchema),
    defaultValues: {
      lastPlaceAttended: pendingInscription?.scolarity?.lastPlaceAttended
        ? 'autre'
        : undefined,
      lastPlaceAttendedAddress:
        pendingInscription?.scolarity?.lastPlaceAttendedAddress ?? undefined,
      lastPlaceAttendedPostalCode:
        pendingInscription?.scolarity?.lastPlaceAttendedPostalCode ?? undefined,
      lastPlaceAttendedCity:
        pendingInscription?.scolarity?.lastPlaceAttendedCity ?? undefined,
      otherLastPlaceAttended:
        pendingInscription?.scolarity?.lastPlaceAttended ?? undefined,
      otherLastPlaceAttendedAddress:
        pendingInscription?.scolarity?.lastPlaceAttendedAddress ?? undefined,
      otherLastPlaceAttendedPostalCode:
        pendingInscription?.scolarity?.lastPlaceAttendedPostalCode ?? undefined,
      otherLastPlaceAttendedCity:
        pendingInscription?.scolarity?.lastPlaceAttendedCity ?? undefined,
      initalGradeLevel: pendingInscription?.scolarity?.initialGradeLevel ?? undefined,
      twelveGradesSpecialitiesOne:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.one ?? undefined,
      twelveGradesSpecialitiesOneAverage:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.averageResults?.one ??
        undefined,
      twelveGradesSpecialitiesOneCommentary:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.commentaries?.one ??
        undefined,
      twelveGradesSpecialitiesTwo:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.two ?? undefined,
      twelveGradesSpecialitiesTwoAverage:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.averageResults?.two ??
        undefined,
      twelveGradesSpecialitiesTwoCommentary:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.commentaries?.two ??
        undefined,
      twelveGradesSpecialitiesVariant:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.variant ?? undefined,
      twelveGradesSpecialitiesVariantAverage:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.averageResults
          ?.variant ?? undefined,
      twelveGradesSpecialitiesVariantCommentary:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.commentaries?.variant ??
        undefined,
      twelveGradesDroppedSpecialities:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.dropped ?? undefined,
      twelveGradesDroppedSpecialitiesAverage:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.averageResults
          ?.dropped ?? undefined,
      twelveGradesDroppedSpecialitiesCommentary:
        pendingInscription?.scolarity?.twelveGradesSpecialities?.commentaries?.dropped ??
        undefined,
      elevenGradesSpecialitiesOne:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.one ?? undefined,
      elevenGradesSpecialitiesOneAverage:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.averageResults?.one ??
        undefined,
      elevenGradesSpecialitiesOneCommentary:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.commentaries?.one ??
        undefined,
      elevenGradesSpecialitiesTwo:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.two ?? undefined,
      elevenGradesSpecialitiesTwoAverage:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.averageResults?.two ??
        undefined,
      elevenGradesSpecialitiesTwoCommentary:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.commentaries?.two ??
        undefined,
      elevenGradesSpecialitiesThree:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.three ?? undefined,
      elevenGradesSpecialitiesThreeAverage:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.averageResults?.three ??
        undefined,
      elevenGradesSpecialitiesThreeCommentary:
        pendingInscription?.scolarity?.elevenGradesSpecialities?.commentaries?.three ??
        undefined,
      learnedLanguage: pendingInscription?.scolarity?.learnedLanguage ?? undefined,
      learnedlanguageAverageResults:
        pendingInscription?.scolarity?.learnedlanguageAverageResults ?? undefined,
      learnedlanguageCommentaries:
        pendingInscription?.scolarity?.learnedlanguageCommentaries ?? undefined,
      studentMotivation: pendingInscription?.scolarity?.motivation ?? undefined,
    },
  });

  const [comboboxSearch, setComboboxSearch] = useState('');
  const debouncedSearchTerm = useDebounce(comboboxSearch, 500);

  const [fetchedSchools, setFetchedSchools] = useState<FrenchHighSchoolAPIItem[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!debouncedSearchTerm || debouncedSearchTerm.length < 5) {
      return;
    }

    setIsFetching(true);

    let whereClause = 'dep IN ("75", "77", "78", "91", "92", "93", "94", "95")';
    whereClause += ` AND (nom_etablissement LIKE '%${debouncedSearchTerm}%' OR adresse_postale LIKE '%${debouncedSearchTerm}%')`;
    const encodedWhereClause = encodeURIComponent(whereClause);
    const openDataUrl = `https://data.iledefrance.fr/api/explore/v2.1/catalog/datasets/lycees-donnees-generales/records?limit=10&offset=0&timezone=UTC&include_links=false&include_app_metas=false&where=${encodedWhereClause}`;

    fetch(openDataUrl)
      .then((response) => response.json())
      .then((data) => {
        setFetchedSchools(data.results);
        setIsFetching(false);
      });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (form.getValues('initalGradeLevel') === 'twelfthGrade') {
      form.setValue('learnedLanguage', 'english');
    } else if (form.getValues('initalGradeLevel') === 'eleventhGrade') {
      form.setValue('learnedLanguage', 'french');
    }
  }, [form, form.watch('initalGradeLevel')]);

  useEffect(() => {
    if (form.getValues('lastPlaceAttended') === 'autre') {
      form.setValue('lastPlaceAttendedAddress', 'Non applicable');
      form.setValue('lastPlaceAttendedCity', 'Non applicable');
      form.setValue('lastPlaceAttendedPostalCode', 'Non applicable');
    }
  }, [
    form,
    form.watch([
      'otherLastPlaceAttended',
      'otherLastPlaceAttendedAddress',
      'otherLastPlaceAttendedCity',
      'otherLastPlaceAttendedPostalCode',
    ]),
  ]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          if (data.lastPlaceAttended === 'autre') {
            if (
              !data.otherLastPlaceAttended ||
              !data.otherLastPlaceAttendedPostalCode ||
              !data.otherLastPlaceAttendedCity
            ) {
              console.error('Missing other last place attended data');
              return;
            }
            data.lastPlaceAttended = data.otherLastPlaceAttended;
            data.lastPlaceAttendedAddress = data.otherLastPlaceAttended;
            data.lastPlaceAttendedPostalCode = data.otherLastPlaceAttendedPostalCode;
            data.lastPlaceAttendedCity = data.otherLastPlaceAttendedCity;
          } else {
            const [nom_etablissement, adresse_postale, code_postal, libelle] =
              data.lastPlaceAttended.split('@|@');

            if (!nom_etablissement || !adresse_postale || !code_postal || !libelle) {
              console.error('Missing school data');
              return;
            }

            data.lastPlaceAttended = nom_etablissement;
            data.lastPlaceAttendedAddress = adresse_postale;
            data.lastPlaceAttendedPostalCode = code_postal;
            data.lastPlaceAttendedCity = libelle;
          }

          const response = await stepThreeSubmit(data);

          if (response) {
            navigate({
              to: '/steps/four',
            });
          } else {
            console.error('Error adding document');
          }
        })}
        className="h-fit w-full bg-white px-7 py-4 text-black/60"
      >
        <SectionTitle title="Scolarité et motivations" />
        <div className="flex items-center">
          <SectionSubtitle title="Année en cours" />
          <Asterisk className="text-xl" />
        </div>
        <section>
          <FormField
            control={form.control}
            name="initalGradeLevel"
            render={({ field }) => (
              <FormItem>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger className="rounded-none border-black/60">
                      <SelectValue placeholder="Selectionnez votre année en cours" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="eleventhGrade">Première</SelectItem>
                    <SelectItem value="twelfthGrade">Terminale</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <div className="flex items-center">
          <SectionSubtitle title="Dernier établissement fréquenté" />
          <Asterisk className="text-xl" />
        </div>
        <section className="space-y-4">
          <FormField
            control={form.control}
            name="lastPlaceAttended"
            render={({ field }) => (
              <FormItem className="w-full focus-within:text-previsionblue">
                <FormDescription className="text-sm">
                  Si votre établissement n'apparaît pas, merci de selectionner "Autre".
                </FormDescription>
                <Combobox
                  placeholder="Tapez les 10 premières lettres de votre établissement"
                  notFoundItem="Aucun établissement trouvé."
                  containerClassName="max-w-[unset] rounded-none border-black/60"
                  isFetching={
                    debouncedSearchTerm.length > 5 ||
                    isFetching ||
                    comboboxSearch.length > 5
                  }
                  value={field.value}
                  setValue={(value) => {
                    field.onChange(value);

                    if (field.value === 'autre') {
                      form.setValue('lastPlaceAttendedAddress', 'Non applicable');
                      form.setValue('otherLastPlaceAttendedAddress', 'Non applicable');
                      return;
                    }

                    const [nom_etablissement, adresse_postale, code_postal, libelle] =
                      value.split('@|@');

                    if (
                      !nom_etablissement ||
                      !adresse_postale ||
                      !code_postal ||
                      !libelle
                    ) {
                      console.error('Missing school data');
                      return;
                    }

                    form.setValue('lastPlaceAttendedAddress', adresse_postale);
                    form.setValue('lastPlaceAttendedCity', libelle);
                    form.setValue('lastPlaceAttendedPostalCode', code_postal);
                  }}
                  items={[
                    ...fetchedSchools.map((school) => ({
                      label: `${school.nom_etablissement} - ${school.adresse_postale}, ${school.code_postal}, ${school.libelle}`,
                      value: `${school.nom_etablissement}@|@${school.adresse_postale}@|@${school.code_postal}@|@${school.libelle}`,
                    })),
                    {
                      label: 'Autre',
                      value: 'autre',
                    },
                  ]}
                  commandInputControledValue={comboboxSearch}
                  commandInputControledValueSetter={setComboboxSearch}
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormDescription className="text-sm">
            Si 'Autre' a été selectionné, veuillez remplir les champs suivants.
          </FormDescription>
          <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="otherLastPlaceAttended"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <Input
                    {...field}
                    placeholder="Nom de l'établissement"
                    variant="prevision"
                    disabled={form.watch('lastPlaceAttended') !== 'autre'}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="otherLastPlaceAttendedPostalCode"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <Input
                    {...field}
                    placeholder="Code postal"
                    variant="prevision"
                    disabled={form.watch('lastPlaceAttended') !== 'autre'}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="otherLastPlaceAttendedCity"
              render={({ field }) => (
                <FormItem className="w-full focus-within:text-previsionblue">
                  <Input
                    {...field}
                    placeholder="Ville"
                    variant="prevision"
                    disabled={form.watch('lastPlaceAttended') !== 'autre'}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </section>

        <SectionSubtitle
          title={`Spécialités ${form.getValues('initalGradeLevel') === 'twelfthGrade' ? 'de Terminale' : 'de Première'}`}
        />
        <section
          className={clsx(
            'mb-4 grid grid-cols-1 gap-2 md:gap-4',
            form.watch('initalGradeLevel') === 'twelfthGrade' ? 'block' : 'hidden',
          )}
        >
          <div className="my-4 grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesOne"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Première spécialité
                      <Asterisk />
                    </FormLabel>
                    <FormControl>
                      <WithCreateCombobox
                        mode="single"
                        options={SPECIALITIES.filter(
                          // if the speciality is already selected in the others fields, we don't show it
                          (speciality) =>
                            speciality !==
                              form.getValues('twelveGradesSpecialitiesTwo') &&
                            speciality !==
                              form.getValues('twelveGradesDroppedSpecialities'),
                        ).map((speciality) => ({
                          value: speciality,
                          label: speciality,
                        }))}
                        placeholder={SPECIALITIES_COMBOBOX_PLACEHOLDER}
                        createMessage="Autre : "
                        createMessageClassName="text-primary font-normal"
                        triggerClassName="rounded-none border-black/60 disabled:bg-black/15 disabled:cursor-not-allowed"
                        selected={field.value}
                        onChange={(value) => {
                          if (field.value === value) {
                            field.onChange(undefined);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        onCreate={(value) => {
                          if (field.value === value) {
                            if (SPECIALITIES.includes(value)) {
                              SPECIALITIES.splice(SPECIALITIES.indexOf(value), 1);
                            }
                            field.onChange(undefined);
                          } else {
                            SPECIALITIES.push(value);
                            field.onChange(value);
                          }
                        }}
                        disabled={
                          form.watch('initalGradeLevel') === 'eleventhGrade' ||
                          !form.getValues('initalGradeLevel')
                        }
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesOneAverage"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Moyenne obtenue (de Première et/ou Terminale si applicable)
                      <Asterisk />
                    </FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesOneCommentary"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Remarques</FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesTwo"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Deuxième spécialité
                      <Asterisk />
                    </FormLabel>
                    <FormControl>
                      <WithCreateCombobox
                        mode="single"
                        options={SPECIALITIES.filter(
                          // if the speciality is already selected in the others fields, we don't show it
                          (speciality) =>
                            speciality !==
                              form.getValues('twelveGradesSpecialitiesOne') &&
                            speciality !==
                              form.getValues('twelveGradesDroppedSpecialities'),
                        ).map((speciality) => ({
                          value: speciality,
                          label: speciality,
                        }))}
                        placeholder={SPECIALITIES_COMBOBOX_PLACEHOLDER}
                        createMessage="Autre : "
                        createMessageClassName="text-primary font-normal"
                        triggerClassName="rounded-none border-black/60 disabled:bg-black/15 disabled:cursor-not-allowed"
                        selected={field.value}
                        onChange={(value) => {
                          if (field.value === value) {
                            field.onChange(undefined);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        onCreate={(value) => {
                          if (field.value === value) {
                            if (SPECIALITIES.includes(value)) {
                              SPECIALITIES.splice(SPECIALITIES.indexOf(value), 1);
                            }
                            field.onChange(undefined);
                          } else {
                            SPECIALITIES.push(value);
                            field.onChange(value);
                          }
                        }}
                        disabled={
                          form.watch('initalGradeLevel') === 'eleventhGrade' ||
                          !form.getValues('initalGradeLevel')
                        }
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesTwoAverage"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Moyenne obtenue (de Première et/ou Terminale si applicable)
                      <Asterisk />
                    </FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesTwoCommentary"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Remarques</FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="twelveGradesDroppedSpecialities"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Spécialité abandonnée en Première
                      <Asterisk />
                    </FormLabel>
                    <FormControl>
                      <WithCreateCombobox
                        mode="single"
                        options={SPECIALITIES.filter(
                          // if the speciality is already selected in the others fields, we don't show it
                          (speciality) =>
                            speciality !==
                              form.getValues('twelveGradesSpecialitiesOne') &&
                            speciality !== form.getValues('twelveGradesSpecialitiesTwo'),
                        ).map((speciality) => ({
                          value: speciality,
                          label: speciality,
                        }))}
                        placeholder={SPECIALITIES_COMBOBOX_PLACEHOLDER}
                        createMessage="Autre : "
                        createMessageClassName="text-primary font-normal"
                        triggerClassName="rounded-none border-black/60 disabled:bg-black/15 disabled:cursor-not-allowed"
                        selected={field.value}
                        onChange={(value) => {
                          if (field.value === value) {
                            field.onChange(undefined);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        onCreate={(value) => {
                          if (field.value === value) {
                            if (SPECIALITIES.includes(value)) {
                              SPECIALITIES.splice(SPECIALITIES.indexOf(value), 1);
                            }
                            field.onChange(undefined);
                          } else {
                            SPECIALITIES.push(value);
                            field.onChange(value);
                          }
                        }}
                        disabled={
                          form.watch('initalGradeLevel') === 'eleventhGrade' ||
                          !form.getValues('initalGradeLevel')
                        }
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="twelveGradesDroppedSpecialitiesAverage"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Moyenne obtenue (de Première)
                      <Asterisk />
                    </FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="twelveGradesDroppedSpecialitiesCommentary"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Remarques</FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="my-4 grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesVariant"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Option (facultatif)</FormLabel>
                    <FormControl>
                      <WithCreateCombobox
                        mode="single"
                        options={TWELVE_GRADES_SPECIALITIES_VARIANT.map((speciality) => ({
                          value: speciality,
                          label: speciality,
                        }))}
                        placeholder={OPTION_COMBOBOX_PLACEHOLDER}
                        createMessage="Autre : "
                        createMessageClassName="text-primary font-normal"
                        triggerClassName="rounded-none border-black/60 disabled:bg-black/15 disabled:cursor-not-allowed"
                        selected={field.value}
                        onChange={(value) => {
                          if (field.value === value) {
                            field.onChange(undefined);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        onCreate={(value) => {
                          if (field.value === value) {
                            if (TWELVE_GRADES_SPECIALITIES_VARIANT.includes(value)) {
                              SPECIALITIES.splice(
                                TWELVE_GRADES_SPECIALITIES_VARIANT.indexOf(value),
                                1,
                              );
                            }
                            field.onChange(undefined);
                          } else {
                            TWELVE_GRADES_SPECIALITIES_VARIANT.push(value);
                            field.onChange(value);
                          }
                        }}
                        disabled={
                          form.watch('initalGradeLevel') === 'eleventhGrade' ||
                          !form.getValues('initalGradeLevel') ||
                          !!pendingInscription?.scolarity?.twelveGradesSpecialities
                            ?.variant
                        }
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesVariantAverage"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Moyenne obtenue (de Première et/ou Terminale si applicable)
                    </FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel') ||
                        !!pendingInscription?.scolarity?.twelveGradesSpecialities
                          ?.averageResults?.variant
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="twelveGradesSpecialitiesVariantCommentary"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Remarques</FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'eleventhGrade' ||
                        !form.getValues('initalGradeLevel') ||
                        !!pendingInscription?.scolarity?.twelveGradesSpecialities
                          ?.commentaries?.variant
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </section>
        <section
          className={clsx(
            '"mb-4 md:gap-4" grid grid-cols-1 gap-2',
            form.watch('initalGradeLevel') !== 'eleventhGrade' &&
              form.watch('initalGradeLevel') !== undefined
              ? 'hidden'
              : 'block',
          )}
        >
          <div className="my-4 grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesOne"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Première spécialité
                      <Asterisk />
                    </FormLabel>
                    <FormControl>
                      <WithCreateCombobox
                        mode="single"
                        options={SPECIALITIES.filter(
                          // if the speciality is already selected in the others fields, we don't show it
                          (speciality) =>
                            speciality !==
                              form.getValues('elevenGradesSpecialitiesTwo') &&
                            speciality !==
                              form.getValues('elevenGradesSpecialitiesThree'),
                        ).map((speciality) => ({
                          value: speciality,
                          label: speciality,
                        }))}
                        placeholder={SPECIALITIES_COMBOBOX_PLACEHOLDER}
                        createMessage="Autre: "
                        createMessageClassName="text-primary font-normal"
                        triggerClassName="rounded-none border-black/60 disabled:bg-black/15 disabled:cursor-not-allowed"
                        selected={field.value}
                        onChange={(value) => {
                          if (field.value === value) {
                            field.onChange(undefined);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        onCreate={(value) => {
                          if (field.value === value) {
                            if (SPECIALITIES.includes(value)) {
                              SPECIALITIES.splice(SPECIALITIES.indexOf(value), 1);
                            }
                            field.onChange(undefined);
                          } else {
                            SPECIALITIES.push(value);
                            field.onChange(value);
                          }
                        }}
                        disabled={
                          form.watch('initalGradeLevel') === 'twelfthGrade' ||
                          !form.getValues('initalGradeLevel')
                        }
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesOneAverage"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Moyenne obtenue (de Seconde et/ou Première si applicable)
                      <Asterisk />
                    </FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'twelfthGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesOneCommentary"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Remarques</FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'twelfthGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="my-4 grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesTwo"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Deuxième spécialité
                      <Asterisk />
                    </FormLabel>
                    <FormControl>
                      <WithCreateCombobox
                        mode="single"
                        options={SPECIALITIES.filter(
                          // if the speciality is already selected in the others fields, we don't show it
                          (speciality) =>
                            speciality !==
                              form.getValues('elevenGradesSpecialitiesOne') &&
                            speciality !==
                              form.getValues('elevenGradesSpecialitiesThree'),
                        ).map((speciality) => ({
                          value: speciality,
                          label: speciality,
                        }))}
                        placeholder={SPECIALITIES_COMBOBOX_PLACEHOLDER}
                        createMessage="Autre : "
                        createMessageClassName="text-primary font-normal"
                        triggerClassName="rounded-none border-black/60 disabled:bg-black/15 disabled:cursor-not-allowed"
                        selected={field.value}
                        onChange={(value) => {
                          if (field.value === value) {
                            field.onChange(undefined);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        onCreate={(value) => {
                          if (field.value === value) {
                            if (SPECIALITIES.includes(value)) {
                              SPECIALITIES.splice(SPECIALITIES.indexOf(value), 1);
                            }
                            field.onChange(undefined);
                          } else {
                            SPECIALITIES.push(value);
                            field.onChange(value);
                          }
                        }}
                        disabled={
                          form.watch('initalGradeLevel') === 'twelfthGrade' ||
                          !form.getValues('initalGradeLevel')
                        }
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesTwoAverage"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Moyenne obtenue (de Seconde et/ou Première si applicable)
                      <Asterisk />
                    </FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'twelfthGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesTwoCommentary"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Remarques</FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'twelfthGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="my-4 grid grid-cols-1 gap-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesThree"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Troisième spécialité
                      <Asterisk />
                    </FormLabel>
                    <FormControl>
                      <WithCreateCombobox
                        mode="single"
                        options={SPECIALITIES.filter(
                          // if the speciality is already selected in the others fields, we don't show it
                          (speciality) =>
                            speciality !==
                              form.getValues('elevenGradesSpecialitiesOne') &&
                            speciality !== form.getValues('elevenGradesSpecialitiesTwo'),
                        ).map((speciality) => ({
                          value: speciality,
                          label: speciality,
                        }))}
                        placeholder={SPECIALITIES_COMBOBOX_PLACEHOLDER}
                        createMessage="Autre : "
                        createMessageClassName="text-primary font-normal"
                        triggerClassName="rounded-none border-black/60 disabled:bg-black/15 disabled:cursor-not-allowed"
                        selected={field.value}
                        onChange={(value) => {
                          if (field.value === value) {
                            field.onChange(undefined);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        onCreate={(value) => {
                          if (field.value === value) {
                            if (SPECIALITIES.includes(value)) {
                              SPECIALITIES.splice(SPECIALITIES.indexOf(value), 1);
                            }
                            field.onChange(undefined);
                          } else {
                            SPECIALITIES.push(value);
                            field.onChange(value);
                          }
                        }}
                        disabled={
                          form.watch('initalGradeLevel') === 'twelfthGrade' ||
                          !form.getValues('initalGradeLevel')
                        }
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesThreeAverage"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">
                      Moyenne obtenue (de Seconde et/ou Première si applicable)
                      <Asterisk />
                    </FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'twelfthGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="elevenGradesSpecialitiesThreeCommentary"
              render={({ field }) => (
                <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                  <div className="flex min-h-24 flex-col justify-between">
                    <FormLabel className="text-base">Remarques</FormLabel>
                    <Input
                      {...field}
                      variant="prevision"
                      disabled={
                        form.watch('initalGradeLevel') === 'twelfthGrade' ||
                        !form.getValues('initalGradeLevel')
                      }
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </section>
        <section className="my-2 grid grid-cols-1 gap-2 md:grid-cols-2">
          <FormField
            control={form.control}
            name="learnedlanguageAverageResults"
            render={({ field }) => (
              <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                <div className="flex min-h-24 flex-col justify-between">
                  <FormLabel className="text-base">
                    Moyenne{' '}
                    {form.watch('initalGradeLevel') === 'eleventhGrade'
                      ? 'de Français'
                      : "d'Anglais"}{' '}
                    obtenue
                    <Asterisk />
                  </FormLabel>
                  <Input
                    {...field}
                    variant="prevision"
                    disabled={!form.getValues('initalGradeLevel')}
                  />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="learnedlanguageCommentaries"
            render={({ field }) => (
              <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                <div className="flex min-h-24 flex-col justify-between">
                  <FormLabel className="text-base">Remarques</FormLabel>
                  <Input
                    {...field}
                    variant="prevision"
                    disabled={!form.getValues('initalGradeLevel')}
                  />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </section>
        <SectionSubtitle title="Motivations" />
        <section className="mb-12 grid grid-cols-1 gap-4 md:gap-6">
          <FormField
            control={form.control}
            name="studentMotivation"
            render={({ field }) => (
              <FormItem className="mb-auto mt-0 focus-within:text-previsionblue">
                <FormLabel className="text-base">
                  Quel métier souhaitez-vous exercer plus tard et pourquoi ?
                </FormLabel>
                <Textarea
                  className="resize-none rounded-none border border-black/60 focus:border-2 focus:border-previsionblue disabled:cursor-not-allowed disabled:bg-black/15"
                  disabled={!form.getValues('initalGradeLevel')}
                  {...field}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </section>

        <div className="flex justify-between">
          <Link to={`/steps/two`}>
            <Button type="button" variant="outlinedBlue" className="w-32">
              Précédent
            </Button>
          </Link>
          <Button
            type="submit"
            variant="squaredBlue"
            className="w-32"
            disabled={
              form.formState.isSubmitting ||
              form.getFieldState('initalGradeLevel').invalid ||
              !form.getValues('initalGradeLevel')
            }
          >
            {form.formState.isSubmitting ? (
              <LoaderCircle className="animate-spin" />
            ) : (
              'Suivant'
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};
