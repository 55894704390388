// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import * as Sentry from '@sentry/react';
import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router';
import { httpsCallable } from 'firebase/functions';
import ls from 'localstorage-slim';
import { useEffect } from 'react';

import { functions } from '@/lib/firebase';
import { NotFoundPage } from '@/pages/404';
import { referentSchema } from '@/pages/inscriptions/referent/referentInformationSchema';
import { SchoolRecord } from '@/types/types';
import { nukeLocalData } from '@/utils/cleaner';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const Route = createFileRoute('/test')({
  loader: async () => {
    if (process.env.NODE_ENV === 'production') {
      throw notFound();
    }
  },
  component: () => <TestComponent />,
  notFoundComponent: () => <NotFoundPage />,
});

const TestComponent = () => {
  const navigate = useNavigate();

  const testLeCsv = async () => {
    const getCSV = httpsCallable(functions, 'generateCSVFile');

    const response = await getCSV({
      recordStatus: 'submitted',
      scholarYear: '2024/2025',
      formuleType: 'Sciences Po / IEP - Formule Complète',
      onlyValidated: true,
      selectedFields: {
        student: false,
        referent: false,
        pastScolarity: false,
        formule: true,
        payment: false,
      },
    });

    const element = document.createElement('a');

    element.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${encodeURI(response.data)}`,
    );
    element.setAttribute('download', 'test.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const testLeMail = async () => {
    const previousLocalstorage: SchoolRecord | null =
      ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

    if (
      !previousLocalstorage ||
      !previousLocalstorage.referent ||
      !previousLocalstorage.student
    ) {
      console.error('Missing referent or student');
      return;
    }

    const referentEmail = previousLocalstorage.referent.contact.email;
    const referentName =
      previousLocalstorage.referent.firstname +
      ' ' +
      previousLocalstorage.referent.lastname;

    const studentEmail = previousLocalstorage.student.contact.email;
    const studentName =
      previousLocalstorage.student.firstname +
      ' ' +
      previousLocalstorage.student.lastname;

    if (!referentEmail || !referentName || !studentEmail || !studentName) {
      console.error('Missing email or name');
      return;
    }

    const sendEmailToUser = httpsCallable(functions, 'sendEmailToUser');

    await sendEmailToUser({
      recipient: {
        referentEmail,
        referentName,
        studentEmail,
        studentName,
      },
      templateName: 'depositPaid',
    }).catch((error) => {
      console.error(`Erreur lors de l'envoi de l'email de confirmation: ${error}`);
    });
  };

  const testEmailSimilaire = async () => {
    const result = referentSchema.safeParse({
      civilite: 'M',
      referentFirstName: 'Jean',
      referentLastName: 'Dupont',
      referentBirthDate: new Date('1990-01-01'),
      referentPlaceOfBirth: 'Paris',
      referentNationality: 'Française',
      referentAddress: '1 rue de la paix',
      referentPostalCode: '75001',
      referentCity: 'Paris',
      referentCountry: 'France',
      hiddedStudentEmail: 'test@test.bloup',
      referentEmail: 'test@test.bloup',
      referentMobilePhone: '+33600000000',
    });
    !result.success && console.log(result.error.issues);
  };

  useEffect(() => {
    console.log('Test page mounted');
    // Provoquer une erreur
    try {
      console.log('Test error from console!');
      throw new Error('Test error from Sentry!');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }, []);

  return (
    <div>
      <button
        onClick={() => {
          nukeLocalData(navigate);
        }}
      >
        Nuke localdata
      </button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <button onClick={testLeCsv}>Test le csv</button> <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <button onClick={() => methodDoesNotExist()}>Break the world</button>;
      <br />
      <br />
      <br />
      <br />
      <br />
      <button onClick={() => testLeMail()}>Test mail sending</button>;
      <br />
      <br />
      <br />
      <br />
      <br />
      <button onClick={() => testEmailSimilaire()}>Test email similaire</button>;
    </div>
  );
};
