import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const editReferentSchema = z.object({
  civility: z.enum(['M', 'F']),
  referentFirstName: z.string().min(1, {
    message: 'Prénom requis',
  }),
  referentLastName: z.string().min(1, {
    message: 'Nom requis',
  }),
  referentBirthDate: z.date({
    required_error: 'Date de naissance requise',
    invalid_type_error: 'Date de naissance invalide',
  }),
  referentPlaceOfBirth: z.string().min(1, {
    message: 'Lieu de naissance requis',
  }),
  referentEmail: z.string().email({
    message: 'Adresse mail invalide',
  }),
  referentMobilePhone: z
    .string()
    .refine(isValidPhoneNumber, { message: 'Numéro de téléphone invalide' }),
});

export const editSecondReferentSchema = z.object({
  secondReferentCivility: z.enum(['M', 'F']),
  secondReferentFirstName: z.string().min(1, {
    message: 'Prénom requis',
  }),
  secondReferentLastName: z.string().min(1, {
    message: 'Nom requis',
  }),
  secondReferentEmail: z.string().email({
    message: 'Adresse mail invalide',
  }),
  secondReferentMobilePhone: z
    .string()
    .refine(isValidPhoneNumber, { message: 'Numéro de téléphone invalide' }),
});

export const termsSchema = z.object({
  gtcConsent: z.boolean({
    required_error: 'Vous devez accepter les conditions générales de vente',
  }),
  emailConsent: z.boolean({
    required_error: 'Vous devez accepter de recevoir des emails',
  }),
  scholarityRulesConsent: z.boolean({
    required_error: 'Vous devez accepter les règles de scolarité',
  }),
  ndaConsent: z.boolean({
    required_error: "Vous devez accepter l'accord de non divulgation",
  }),
  imageUseConsent: z.boolean({
    required_error: "Vous devez accepter l'utilisation de vos images",
  }),
  studentLegalResponsibilityWaiver: z.boolean({
    required_error: 'Vous devez accepter les responsabilités légales',
  }),
});

export const loginSchema = z.object({
  email: z.string().email({ message: "L'adresse e-mail n'est pas valide" }),
  password: z
    .string()
    .min(6, { message: 'Le mot de passe doit contenir au moins 6 caractères' }),
});

export const addFormuleSchema = z.object({
  schoolYear: z.string(),
  courseType: z.string(),
  courseTitle: z.string(),
  coursePrice: z.string(),
  courseReduction: z.string(),
  courseDoesExpire: z.boolean(),
  courseExpirationDate: z.string().optional(),
  position: z.string().optional(),
});
