import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const referentSchema = z
  .object({
    hasSecondReferent: z.boolean(),
    civility: z.enum(['M', 'F']),
    referentFirstName: z.string().min(1, {
      message: 'Prénom requis',
    }),
    referentLastName: z.string().min(1, {
      message: 'Nom requis',
    }),
    referentBirthDate: z.date({
      required_error: 'Date de naissance requise',
      invalid_type_error: 'Date de naissance invalide',
    }),
    referentPlaceOfBirth: z.string().min(1, {
      message: 'Lieu de naissance requis',
    }),
    hiddedStudentEmail: z.string().email(),
    hiddedStudentMobilePhone: z.string(),
    referentEmail: z.string().email({
      message: 'Adresse mail invalide',
    }),
    referentMobilePhone: z
      .string()
      .refine(isValidPhoneNumber, { message: 'Numéro de téléphone invalide' }),
    secondReferentCivility: z.enum(['M', 'F']).optional(),
    secondReferentFirstName: z.string().optional(),
    secondReferentLastName: z.string().optional(),
    secondReferentEmail: z
      .string()
      .email({
        message: 'Adresse mail invalide',
      })
      .optional(),
    secondReferentMobilePhone: z
      .string()
      .refine(isValidPhoneNumber, { message: 'Numéro de téléphone invalide' })
      .optional(),
  })
  .superRefine((val, ctx) => {
    let isCorrect = true;

    if (val.referentEmail === val.hiddedStudentEmail) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `L'email du responsable légal ne peut pas être le même que celui de l'élève`,
        path: ['referentEmail'],
      });

      isCorrect = false;
    }

    if (val.secondReferentEmail === val.hiddedStudentEmail) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `L'email du deuxième responsable légal ne peut pas être le même que celui de l'élève`,
        path: ['secondReferentEmail'],
      });

      isCorrect = false;
    }

    if (val.secondReferentEmail === val.referentEmail) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `L'email des responsables légaux ne peuvent pas être identiques`,
        path: ['secondReferentEmail'],
      });

      isCorrect = false;
    }

    if (val.referentMobilePhone === val.hiddedStudentMobilePhone) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Le numéro de téléphone du responsable légal ne peut pas être le même que celui de l'élève`,
        path: ['referentMobilePhone'],
      });

      isCorrect = false;
    }

    if (val.secondReferentMobilePhone === val.hiddedStudentMobilePhone) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Le numéro de téléphone du deuxième responsable légal ne peut pas être le même que celui de l'élève`,
        path: ['secondReferentMobilePhone'],
      });

      isCorrect = false;
    }

    if (val.secondReferentMobilePhone === val.referentMobilePhone) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Les numéros de téléphone des responsables légaux ne peuvent pas être identiques`,
        path: ['secondReferentMobilePhone'],
      });

      isCorrect = false;
    }

    const secondReferentFields: (keyof typeof val)[] = [
      'secondReferentCivility',
      'secondReferentFirstName',
      'secondReferentLastName',
      'secondReferentEmail',
      'secondReferentMobilePhone',
    ];

    if (val.hasSecondReferent) {
      const isInvalidFilledFields = secondReferentFields.some(
        (field) => val[field] === undefined || val[field] === '',
      );

      if (isInvalidFilledFields) {
        for (const field of secondReferentFields) {
          if (val[field] === undefined || val[field] === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Veuillez remplir tous les champs du deuxième responsable légal`,
              path: [field],
            });
          }
        }

        isCorrect = false;
      }
    }

    return isCorrect;
  });
