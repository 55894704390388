'use client';

import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

export interface ComboboxItem {
  value: string;
  label: string;
}

export function Combobox({
  items,
  value,
  setValue,
  commandInputControledValue,
  commandInputControledValueSetter,
  placeholder = 'Select item...',
  notFoundItem = 'No item found.',
  containerClassName = '',
  popoverClassName = '',
  isFetching = false,
}: {
  items: ComboboxItem[];
  value: string;
  setValue: (value: string) => void;
  commandInputControledValue?: string;
  commandInputControledValueSetter?: (value: string) => void;
  placeholder?: string;
  notFoundItem?: string;
  containerClassName?: string;
  popoverClassName?: string;
  isFetching?: boolean;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'w-full min-w-[350px] max-w-[750px] justify-between',
            containerClassName,
          )}
        >
          {value ? items.find((item) => item.value === value)?.label : placeholder}
          <ChevronsUpDown className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn('w-full min-w-[350px] max-w-[750px] p-0', popoverClassName)}
      >
        <Command>
          {commandInputControledValue !== undefined &&
          commandInputControledValueSetter !== undefined ? (
            <CommandInput
              placeholder={placeholder}
              className="h-9"
              onValueChange={(value) => commandInputControledValueSetter(value)}
              value={commandInputControledValue}
            />
          ) : (
            <CommandInput placeholder={placeholder} className="h-9" />
          )}
          <CommandList>
            <CommandEmpty>{notFoundItem}</CommandEmpty>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}
                >
                  {item.label}
                  <Check
                    className={cn(
                      'ml-auto',
                      value === item.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>

            {isFetching && (
              <CommandEmpty>
                <div className="flex items-center justify-center">
                  <span className="mr-3 h-5 w-5 animate-spin rounded-full border-b-2 border-t-2 border-gray-900" />
                  <span>Loading...</span>
                </div>
              </CommandEmpty>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
