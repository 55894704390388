import { fromUnixTime } from 'date-fns';
import { signInWithEmailAndPassword, User } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import ls from 'localstorage-slim';
import { toast } from 'sonner';
import { z } from 'zod';

import { getAnonUser } from '@/auth/user';
import { termsSchema } from '@/data/forms-schema.ts';
import { auth, db, storage } from '@/lib/firebase';
import {
  FileType,
  FormuleChoice,
  FormuleForChoice,
  Payment,
  PaymentSchedule,
  Person,
  RecordFile,
  SchoolRecord,
  SchoolRecordEnforced,
  Scolarity,
  SimplifiedPerson,
  StripePaymentData,
  Student,
  Terms,
} from '@/types/types';
import { LOCALSTORAGE_INSRIPTION_KEY } from '@/utils/constants';

export const loginSubmit = async (email: string, password: string) => {
  await signInWithEmailAndPassword(auth, email, password);
};

export const stepFourSubmit = async (data: FormuleForChoice) => {
  const user: User = await getAnonUser();

  const previousData: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  const userUid =
    previousData && previousData.recordId ? previousData.recordId : user.uid;

  const formuleInformations: FormuleChoice = {
    formuleReference: doc(db, 'formules', data.id),
    formuleId: data.id,
    //If data.price is not a number, parse it to a number
    cost: parseFloat(data.price),
    year: data.year,
    title: data.title,
  };

  const priceWithoutDeposit = (parseFloat(data.price) - 190) * 100;
  const priceWithoutDepositByMonth = priceWithoutDeposit / 10;

  const scheduleItemsPlaceholder: PaymentSchedule[] = [
    {
      paymentStatus: 'pending',
      paymentType: 'accompte',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: 190 * 100,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'september',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'october',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'november',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'december',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'january',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'february',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'march',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'april',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'may',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'june',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: priceWithoutDepositByMonth,
      paymentReduction: 0,
      paymentReference: null,
    },
    {
      paymentStatus: 'pending',
      paymentType: 'total',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: parseFloat(data.price) * 100,
      paymentReduction: 0,
      paymentReference: null,
    },
  ];

  const recordData: Partial<SchoolRecord> = {
    formProgress:
      previousData && previousData?.formProgress >= 4 ? previousData.formProgress : 4,

    formule: formuleInformations,
    payments: {
      stripeID: '',
      refunded: false,
      totalAmount: parseFloat(data.price) * 100,
      totalReduction: 0,
      totalPaid: 0,
      schedule: scheduleItemsPlaceholder,
    },

    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', userUid);

    await setDoc(docRef, recordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  try {
    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(previousData || {}),
      ...recordData,
    });
  } catch (error) {
    console.error('Error adding local: ', error);
    return false;
  }

  return true;
};

const sendFile = async (file: File, fileType: FileType, userIDOverride?: string) => {
  const user: User = await getAnonUser();

  const previousData: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  let userUid: string;
  if (userIDOverride) {
    userUid = userIDOverride;
  } else {
    userUid = previousData && previousData.recordId ? previousData.recordId : user.uid;
  }

  if (file) {
    const path = `inscriptions/pending/${userUid}/${fileType}`;
    const fileRef = ref(storage, path);

    try {
      await uploadBytes(fileRef, file);
    } catch (error) {
      console.error('Error uploading file: ', error);
      toast("Erreur lors de l'envoi du fichier");
      return false;
    }

    toast('Fichier envoyé !');
    return path;
  }

  return null;
};

export const saveDocument = async (file: File, section: FileType) => {
  const user: User = await getAnonUser();

  const previousData: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  const userUid =
    previousData && previousData.recordId ? previousData.recordId : user.uid;

  const studentFiles: RecordFile[] = previousData?.files || [];

  const path = await sendFile(file, section);

  if (path) {
    //Check if file already exists
    const fileIndex = studentFiles.findIndex((file) => file.type === section);

    if (fileIndex >= 0) {
      if (studentFiles[fileIndex]) {
        studentFiles[fileIndex].url = path;
      } else {
        // Gérer le cas où studentFiles[fileIndex] est indéfini
        studentFiles[fileIndex] = { type: section, name: file.name, url: path };
      }
    } else {
      studentFiles.push({
        type: section,
        name: file.name,
        url: path,
      });
    }
  }

  const recordData: Partial<SchoolRecord> = {
    formProgress:
      previousData && previousData.formProgress >= 5 ? previousData.formProgress : 5,
    files: studentFiles,
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', userUid);

    await setDoc(docRef, recordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  try {
    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(previousData || {}),
      ...recordData,
    });
  } catch (error) {
    console.error('Error adding local: ', error);
    return false;
  }
};

export const saveFileArrayFromForm = async (newRecord: Partial<SchoolRecord>) => {
  const getLocal: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  if (!getLocal?.recordId) return false;

  try {
    const docRef = doc(db, 'inscriptions', getLocal.recordId);

    await setDoc(docRef, newRecord, {
      merge: true,
    });

    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(getLocal || {}),
      ...newRecord,
    });
    return true;
  } catch (error) {
    console.error('Error adding document: ', error);
    toast("Une erreur s'est produite, veuillez réessayer.");
    return false;
  }
};

export const stepSixSubmit = async (data: z.infer<typeof termsSchema>) => {
  const user: User = await getAnonUser();

  const previousData: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  const userUid =
    previousData && previousData.recordId ? previousData.recordId : user.uid;

  const termsInformations: Terms = {
    termsAccepted: Object.entries(data)
      .filter(([accepted]) => accepted)
      .map(([name]) => ({ name, date: new Date() })),
    termsDate: new Date(),
  };

  const recordData: Partial<SchoolRecord> = {
    formProgress:
      previousData && previousData.formProgress >= 6 ? previousData.formProgress : 6,

    terms: termsInformations,

    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', userUid);

    await setDoc(docRef, recordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  try {
    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(previousData || {}),
      ...recordData,
    });
  } catch (error) {
    console.error('Error adding local: ', error);
    return false;
  }

  return true;
};

export const editRecord = async (
  previousSchoolRecord: SchoolRecordEnforced,
  editedSection:
    | 'student'
    | 'referent'
    | 'secondReferent'
    | 'scolarity'
    | 'formule'
    | 'terms'
    | 'payments',
  editedData:
    | Student
    | Person
    | SimplifiedPerson
    | Scolarity
    | FormuleChoice
    | Terms
    | Payment,
) => {
  await auth.authStateReady();
  const user: User | null = auth.currentUser;

  if (!user) {
    return false;
  }

  let newRecordData: SchoolRecordEnforced;

  switch (editedSection) {
    case 'student':
      newRecordData = {
        ...previousSchoolRecord,
        student: editedData as Student,
        updatedAt: new Date(),
      };
      break;
    case 'referent':
      newRecordData = {
        ...previousSchoolRecord,
        referent: editedData as Person,
        updatedAt: new Date(),
      };
      break;
    case 'secondReferent':
      newRecordData = {
        ...previousSchoolRecord,
        secondReferent: editedData as SimplifiedPerson,
        updatedAt: new Date(),
      };
      break;
    case 'scolarity':
      newRecordData = {
        ...previousSchoolRecord,
        scolarity: editedData as Scolarity,
        updatedAt: new Date(),
      };
      break;
    case 'formule':
      newRecordData = {
        ...previousSchoolRecord,
        formule: editedData as FormuleChoice,
        updatedAt: new Date(),
      };
      break;
    case 'terms':
      newRecordData = {
        ...previousSchoolRecord,
        terms: editedData as Terms,
        updatedAt: new Date(),
      };
      break;
    case 'payments':
      newRecordData = {
        ...previousSchoolRecord,
        payments: editedData as Payment,
        updatedAt: new Date(),
      };
      break;
    default:
      newRecordData = previousSchoolRecord;
      break;
  }

  try {
    const docRef = doc(db, 'inscriptions', previousSchoolRecord.recordId);

    await setDoc(docRef, newRecordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  return true;
};

export const editDocumentFromRecord = async (
  previousSchoolRecord: SchoolRecordEnforced,
  file: File,
  section: FileType,
) => {
  const path = file && (await sendFile(file, section, previousSchoolRecord.recordId));

  if (path) {
    const studentFiles: RecordFile[] = previousSchoolRecord.files || [];

    const fileIndex = studentFiles.findIndex((file) => file.type === section);

    if (fileIndex >= 0) {
      if (studentFiles[fileIndex]) {
        studentFiles[fileIndex].url = path;
      } else {
        // Gérer le cas où studentFiles[fileIndex] est indéfini
        studentFiles[fileIndex] = { type: section, name: file.name, url: path };
      }
    } else {
      studentFiles.push({
        type: section,
        name: file.name,
        url: path,
      });
    }

    const newRecordData: SchoolRecordEnforced = {
      ...previousSchoolRecord,
      files: studentFiles,
      updatedAt: new Date(),
    };

    try {
      const docRef = doc(db, 'inscriptions', previousSchoolRecord.recordId);

      await setDoc(docRef, newRecordData, {
        merge: true,
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      return false;
    }

    return true;
  }

  return false;
};

export const updateRecordNote = async (
  previousSchoolRecord: SchoolRecordEnforced,
  newNote: string,
) => {
  const newRecordData: SchoolRecordEnforced = {
    ...previousSchoolRecord,
    recordNote: newNote,
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', previousSchoolRecord.recordId);

    await setDoc(docRef, newRecordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  return true;
};

export const stepSevenSubmit = async (paymentData: StripePaymentData) => {
  const user: User = await getAnonUser();

  const previousData: SchoolRecord | null = ls.get(LOCALSTORAGE_INSRIPTION_KEY) || null;

  const userUid =
    previousData && previousData.recordId ? previousData.recordId : user.uid;

  //Update previous payments & schedule with the new payment
  const schedule = previousData?.payments?.schedule || [];
  const totalPaid = previousData?.payments?.totalPaid || 0;
  const totalAmount = previousData?.payments?.totalAmount || 0;

  const newPayment: PaymentSchedule = {
    paymentStatus: 'paid',
    paymentType: 'accompte',
    paymentDate: fromUnixTime(paymentData.created),
    paymentMethod: 'stripe',
    paymentAmount: paymentData.amount,
    paymentReduction: 0,
    paymentReference: paymentData.id,
  };

  const newSchedule = schedule.map((item) =>
    item.paymentType === 'accompte' ? newPayment : item,
  );

  const newTotalPaid = totalPaid + paymentData.amount;

  const paymentDataRecord: Payment = {
    stripeID: paymentData.id,
    refunded: false,
    totalAmount,
    totalReduction: 0,
    totalPaid: newTotalPaid,
    schedule: newSchedule,
  };

  //Si l'acompte a déjà été payé, on ne fait rien
  if (
    schedule.find((item) => item.paymentType === 'accompte')?.paymentStatus === 'paid'
  ) {
    return false;
  }

  //Si l'acompte a été payé passer le dossier en 'submitted'
  const recordStatus = newPayment.paymentStatus === 'paid' ? 'submitted' : 'pending';

  const recordData: Partial<SchoolRecord> = {
    formProgress:
      previousData && previousData.formProgress >= 7 ? previousData.formProgress : 8,
    recordStatus,
    payments: paymentDataRecord,
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', userUid);

    await setDoc(docRef, recordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  try {
    ls.set(LOCALSTORAGE_INSRIPTION_KEY, {
      ...(previousData || {}),
      ...recordData,
    });
  } catch (error) {
    console.error('Error adding local: ', error);
    return false;
  }

  return true;
};

export const switchRecordStatus = async (
  previousSchoolRecord: SchoolRecordEnforced,
  newStatus: 'pending' | 'submitted' | 'cancelled' | 'archived' | 'deleted',
) => {
  const newRecordData: SchoolRecordEnforced = {
    ...previousSchoolRecord,
    recordStatus: newStatus,
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', previousSchoolRecord.recordId);

    await setDoc(docRef, newRecordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  return true;
};

export const switchRecordValidation = async (
  previousSchoolRecord: SchoolRecordEnforced,
  newValidation: boolean,
) => {
  const newRecordData: SchoolRecordEnforced = {
    ...previousSchoolRecord,
    isRecordValidated: newValidation,
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', previousSchoolRecord.recordId);

    await setDoc(docRef, newRecordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  return true;
};

export const changeDepositToPaid = async (
  previousSchoolRecord: SchoolRecordEnforced,
  paymentMethod: string,
  paymentReference?: string,
) => {
  if (!previousSchoolRecord.payments || !previousSchoolRecord.payments.schedule)
    return false;

  const chequeRefOrNull = paymentReference || null;

  const newPayment: PaymentSchedule = {
    paymentStatus: 'paid',
    paymentType: 'accompte',
    paymentDate: new Date(),
    paymentMethod: paymentMethod,
    paymentAmount: 190 * 100,
    paymentReduction: 0,
    paymentReference: chequeRefOrNull,
  };

  const schedule = previousSchoolRecord.payments.schedule.map((item) =>
    item.paymentType === 'accompte' ? newPayment : item,
  );

  const newRecordData: SchoolRecordEnforced = {
    ...previousSchoolRecord,
    payments: {
      ...previousSchoolRecord.payments,
      schedule,
      totalPaid: 190 * 100,
    },
    recordStatus: 'submitted',
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', previousSchoolRecord.recordId);

    await setDoc(docRef, newRecordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  return true;
};

export const changeDepositToRefunded = async (
  previousSchoolRecord: SchoolRecordEnforced,
) => {
  if (!previousSchoolRecord.payments || !previousSchoolRecord.payments.schedule)
    return false;

  let oldDeposit = previousSchoolRecord.payments.schedule.find(
    (item) => item.paymentType === 'accompte',
  );

  if (!oldDeposit) {
    oldDeposit = {
      paymentStatus: 'refunded',
      paymentType: 'accompte',
      paymentDate: null,
      paymentMethod: null,
      paymentAmount: 190 * 100,
      paymentReduction: 0,
      paymentReference: null,
    };
  } else {
    oldDeposit.paymentStatus = 'refunded';
  }

  const schedule = previousSchoolRecord.payments.schedule.map((item) =>
    item.paymentType === 'accompte' ? oldDeposit : item,
  );

  const newRecordData: SchoolRecordEnforced = {
    ...previousSchoolRecord,
    payments: {
      ...previousSchoolRecord.payments,
      refunded: true,
      schedule,
    },
    updatedAt: new Date(),
  };

  try {
    const docRef = doc(db, 'inscriptions', previousSchoolRecord.recordId);

    await setDoc(docRef, newRecordData, {
      merge: true,
    });
  } catch (error) {
    console.error('Error adding document: ', error);
    return false;
  }

  return true;
};
